import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Customer } from '../models/customer';
import { EnvConfig } from '../services/app-env-config';

@Injectable()
export class CustomerInfoService {

  apiUrlEnrollment;
  emailValidateUrl;

  private handleError: HandleError;

  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private envConfig: EnvConfig) {

    // pass this class name here;
    this.handleError = httpErrorHandler.createHandleError('CustomerInfoService');
    this.apiUrlEnrollment = this.envConfig.get('apiUrlEnrollment');
    this.emailValidateUrl = this.envConfig.get('emailValidateUrl');
    
  }

  // validate emai, if alrady eaxist get customer data
  validateEmail(corporateEntityId, email: string): Observable<any> {

    const httpOptions = { params: new HttpParams().set('email', email).set('corporateEntityId', corporateEntityId) };

    return this.http.get<any>(this.apiUrlEnrollment + '/Customer/GetCustomerByEmail', httpOptions)
      .pipe(
        catchError(this.handleError('validateEmail', []))
      );
  }
      // Validate email with return path validation api
      validateEmailEmailReturnPath(email): Observable<any> {
        const httpOptions = { params: new HttpParams().set('email', email) };
        return this.http.get(this.emailValidateUrl, httpOptions);
    }
}
