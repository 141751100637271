import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from '../../shared/services/http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EnvConfig } from '../../shared/services/app-env-config';

@Injectable()
export class ClaimService {

  apiUrlClaim;
  emailValidateUrl;

  private handleError: HandleError;

  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private envConfig: EnvConfig) {
    this.handleError = httpErrorHandler.createHandleError('EnrollmentService');

    this.apiUrlClaim = this.envConfig.get('apiUrlClaim');
    this.emailValidateUrl = this.envConfig.get('emailValidateUrl');
  }

  checkValidityForFileClaim(customerId, policyId): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('customerId', customerId)
        .set('policyId', policyId)
    };
    return this.http.get<any>(this.apiUrlClaim + '/Claim/CheckValidityForFileClaim', httpOptions)
      .pipe(
        catchError(this.handleError('checkValidityForFileClaim'))
      );
  }

  claimSubmit(data: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post(this.apiUrlClaim + '/Claim/SubmitClaim', data, httpOptions)
      .pipe(
        catchError(this.handleError('enroll', []))
      );
  }

  withdrawClaim(data: any): Observable<any> {
    // const httpOptions = {
    //   headers: new HttpHeaders(
    //     {
    //       'Content-Type': 'application/json',
    //       'corporateEntityCode': '300',
    //       'authorization': 'Basic YmVuLmtlbGx5OjEyMzRxd2VS',
    //       'adminSiteUsername': "ben.kelly",
    //       'adminSitePassword': '1234qweR'
    //     }
    //   )
    // };

    return this.http.post(this.apiUrlClaim + '/Claim/SaveWithDrawnClaimNote', data)
      .pipe(
        catchError(this.handleError('enroll', []))
      );
  }

        // Validate email with return path validation api
        validateEmailEmailReturnPath(email): Observable<any> {
          const httpOptions = { params: new HttpParams().set('email', email) };
          return this.http.get(this.emailValidateUrl, httpOptions);
      }

}
