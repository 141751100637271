<!--Middle section-->
<div class="clear"></div>
<div class="container-full page-ht">
   <div>
      <img [src]="bannerUrl"  class="img-responsive">
   </div>
   <div class="wrap-sub-con">
      <div class="clear gap-top20"></div>
      <div class="clear gap-top20"></div>

      <!--form content-->
      <div class="wrap-frm-cont">
         <h4 class="form-title">FAQs</h4>
         <div class="wrap-instructions">
            
         </div>
         <div>
             <div class="single-section-main" id="terms2" [innerHTML] = "faq">
                 <!-- <div [innerHTML] = "faq"></div> -->
                 <!-- <div class="single-section">
                     <h4>What is covered with Screen Warranty Program?</h4>
                     <p>Screen Warranty Program covers against accidental damage to your device screen (screen breakage or material damage to the screen, excludes intentional acts). Access the full program Terms and Conditions <span class="link-text terms-and-conditions-link" (click)="showTermsAndConditionsModal()">here</span>.</p>
                 </div>
                 <div class="single-section">
                     <h4>What is not covered with Screen Warranty Program?</h4>
                     <p>Extensive physical damage to the device (such as a device run over by a vehicle) will result in a disqualified claim.</p>
                 </div>
                 <div class="single-section">
                     <h4>When will my coverage begin with Screen Warranty Program?</h4>
                     <p>You must register your product and the device for which it covers, within 30 days of purchase for coverage to become effective. Coverage is then effect for 12 months from registration date.</p>
                 </div>
                 <div class="single-section">
                     <h4>What information will I need to register my Screen Warranty?</h4>
                     <ul>
                         <li>Registration Number (located inside purchased packaging)</li>
                         <li>Proof of Purchase (sales receipt must include the line item of the product and date of purchase)</li>
                         <li>Information about your covered device (IMEI/ESN/Serial Number, Make, Model etc.)</li>
                     </ul>
                 </div>
                 <div class="single-section">
                     <h4>How do I file a Screen Warranty claim?</h4>
                     <p>Please visit <a href="https://www.screenwarranty.cellbrokerage.com" target="_blank">https://www.screenwarranty.cellbrokerage.com</a> to file a claim.</p>
                 </div>
                 <div class="single-section">
                     <h4>Are there restrictions on where I can get my Limited Warranty! Plus Liquid Glass Warranty screen repaired?</h4>
                     <p>No. You can have your screen repaired at the location of your choice.</p>
                 </div>
                 <div class="single-section"> 
                 <h4>Where do I upload my Screen Warranty receipt when my repair is complete?</h4>
                 <p>Visit <a href="https://www.screenwarranty.cellbrokerage.com" target="_blank">https://www.screenwarranty.cellbrokerage.com</a> to file a claim. If after the claim is filed, you may email your repair/deductible receipt to <a href="mailto:repairs@cellbrokerage.com">repairs@cellbrokerage.com</a> with your claim number on the subject line.</p>
                 </div>
                 <div class="single-section">
                     <h4>How many Screen Warranty claims can I make?</h4>
                     <p>You can make one claim with each Screen Warranty Screen Protector purchased within the 12-month coverage period from the purchase date.</p>
                 </div>
                 <div class="clear pad-top20"></div> -->
             </div>
         </div>
      </div>
      <!--form content-->
   </div>
</div>
<div class="clear"></div>
<div>
   <a href="#0" class="cd-top">Top</a>
</div>
<div class="clear"></div>
<!--Middle section End-->
<lib-modal-popup ></lib-modal-popup>