import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvConfig } from '../../shared/services/app-env-config';
import { HttpErrorHandler, HandleError } from '../../shared/services/http-error-handler.service';

@Injectable()
export class CheckDeliveryService {

 private handleError: HandleError;
 private apiUrlClaim;


 constructor(private http: HttpClient,
 private httpErrorHandler: HttpErrorHandler,
 private envConfig: EnvConfig) {

 // pass this class name here;
 this.handleError = httpErrorHandler.createHandleError('CheckDeliveryService');

 this.apiUrlClaim = this.envConfig.get('apiUrlClaim');
 }

 public getShippingDetails(customerId): Observable<any> {
 const httpOptions = {
 params: new HttpParams()
 .set('customerId', customerId)
 };
 return this.http.get<any>(this.apiUrlClaim + '/Claim/GetShippingDetails', httpOptions)
 .pipe(
 catchError(this.handleError('getShippingDetails'))
 );
 }

}
