/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./claim-confirmation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/cbfw-claim-confirmation/cbfw-claim-confirmation.ngfactory";
import * as i3 from "cbfw-claim-confirmation";
import * as i4 from "./claim-confirmation.component";
import * as i5 from "@angular/router";
import * as i6 from "../../services/claim-data-management.service";
import * as i7 from "../../../shared/services/app-device.service";
import * as i8 from "../../../shared/services/local-storage.service";
var styles_ClaimConfirmationComponent = [i0.styles];
var RenderType_ClaimConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClaimConfirmationComponent, data: {} });
export { RenderType_ClaimConfirmationComponent as RenderType_ClaimConfirmationComponent };
export function View_ClaimConfirmationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { claimConfirmationComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "lib-claim-confirmation", [], null, [[null, "loadEvent"], [null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadEvent" === en)) {
        var pd_0 = (_co.dataLoad() !== false);
        ad = (pd_0 && ad);
    } if (("closeEvent" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CbfwClaimConfirmationComponent_0, i2.RenderType_CbfwClaimConfirmationComponent)), i1.ɵdid(2, 114688, [[1, 4]], 0, i3.CbfwClaimConfirmationComponent, [], null, { loadEvent: "loadEvent", closeEvent: "closeEvent" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ClaimConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-claim-confirmation", [], null, null, null, View_ClaimConfirmationComponent_0, RenderType_ClaimConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i4.ClaimConfirmationComponent, [i5.Router, i6.ClaimDataManagementService, i7.AppDeviceService, i8.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClaimConfirmationComponentNgFactory = i1.ɵccf("app-claim-confirmation", i4.ClaimConfirmationComponent, View_ClaimConfirmationComponent_Host_0, {}, {}, []);
export { ClaimConfirmationComponentNgFactory as ClaimConfirmationComponentNgFactory };
