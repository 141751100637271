/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./claim-wizard.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/stepper/typings/index.ngfactory";
import * as i3 from "@angular/material/stepper";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/core";
import * as i7 from "@angular/cdk/stepper";
import * as i8 from "../../../../../node_modules/cbfw-incident-details-step/cbfw-incident-details-step.ngfactory";
import * as i9 from "cbfw-incident-details-step";
import * as i10 from "@angular/forms";
import * as i11 from "../../../../../node_modules/cbfw-invoice-step/cbfw-invoice-step.ngfactory";
import * as i12 from "cbfw-invoice-step";
import * as i13 from "@angular/router";
import * as i14 from "../../../../../node_modules/cbfw-check-delivery-step/cbfw-check-delivery-step.ngfactory";
import * as i15 from "cbfw-check-delivery-step";
import * as i16 from "../../../../../node_modules/cbfw-claim-summary-step/cbfw-claim-summary-step.ngfactory";
import * as i17 from "cbfw-claim-summary-step";
import * as i18 from "../../../../../node_modules/cbfw-close-warning-modal-popup/cbfw-close-warning-modal-popup.ngfactory";
import * as i19 from "cbfw-close-warning-modal-popup";
import * as i20 from "./claim-wizard.component";
import * as i21 from "cbfw-confirm-dialog";
import * as i22 from "ng4-loading-spinner";
import * as i23 from "../../../shared/services/state.service";
import * as i24 from "../../../shared/services/app-env-config";
import * as i25 from "../../services/claim-data-management.service";
import * as i26 from "../../services/invoice.service";
import * as i27 from "../../services/claim.service";
import * as i28 from "../../services/check-delivery.service";
import * as i29 from "../../../shared/services/app-device.service";
import * as i30 from "../../../shared/services/local-storage.service";
var styles_ClaimWizardComponent = [i0.styles];
var RenderType_ClaimWizardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClaimWizardComponent, data: {} });
export { RenderType_ClaimWizardComponent as RenderType_ClaimWizardComponent };
function View_ClaimWizardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "hide-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Incident Details"]))], null, null); }
function View_ClaimWizardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "hide-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invoice"]))], null, null); }
function View_ClaimWizardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "hide-text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Check Delivery"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.steperItemThree; _ck(_v, 0, 0, currVal_0); }); }
function View_ClaimWizardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "hide-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Summary"]))], null, null); }
export function View_ClaimWizardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { invoiceComponent: 0 }), i1.ɵqud(402653184, 2, { checkDeliveryComponent: 0 }), i1.ɵqud(402653184, 3, { claimSummaryComponent: 0 }), i1.ɵqud(402653184, 4, { incidentDetailsComponent: 0 }), i1.ɵqud(402653184, 5, { cbfwCloseWarningModalPopupComponent: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 36, "mat-horizontal-stepper", [["aria-orientation", "horizontal"], ["class", "mat-stepper-horizontal"], ["role", "tablist"]], [[2, "mat-stepper-label-position-end", null], [2, "mat-stepper-label-position-bottom", null]], null, null, i2.View_MatHorizontalStepper_0, i2.RenderType_MatHorizontalStepper)), i1.ɵdid(6, 5423104, [["stepper", 4]], 2, i3.MatHorizontalStepper, [[2, i4.Directionality], i1.ChangeDetectorRef, i1.ElementRef, i5.DOCUMENT], { linear: [0, "linear"] }, null), i1.ɵqud(603979776, 6, { _steps: 1 }), i1.ɵqud(603979776, 7, { _icons: 1 }), i1.ɵprd(2048, null, i3.MatStepper, null, [i3.MatHorizontalStepper]), (_l()(), i1.ɵeld(10, 0, null, null, 7, "mat-step", [], null, null, null, i2.View_MatStep_0, i2.RenderType_MatStep)), i1.ɵprd(6144, null, i6.ErrorStateMatcher, null, [i3.MatStep]), i1.ɵdid(12, 573440, [[6, 4]], 1, i3.MatStep, [i3.MatStepper, [1, i6.ErrorStateMatcher], [2, i7.STEPPER_GLOBAL_OPTIONS]], { stepControl: [0, "stepControl"] }, null), i1.ɵqud(335544320, 8, { stepLabel: 0 }), (_l()(), i1.ɵand(0, null, 0, 1, null, View_ClaimWizardComponent_1)), i1.ɵdid(15, 16384, [[8, 4]], 0, i3.MatStepLabel, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "lib-incident-details-step", [], null, [[null, "incidentDetailsForm"], [null, "incidentDetailsData"], [null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("incidentDetailsForm" === en)) {
        var pd_0 = (_co.incidentDetailsFormEventHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("incidentDetailsData" === en)) {
        var pd_1 = (_co.setIncidentDetailsData($event) !== false);
        ad = (pd_1 && ad);
    } if (("closeEvent" === en)) {
        var pd_2 = (_co.closeClaimWiz() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_CbfwIncidentDetailsStepComponent_0, i8.RenderType_CbfwIncidentDetailsStepComponent)), i1.ɵdid(17, 114688, [[4, 4]], 0, i9.CbfwIncidentDetailsStepComponent, [i10.FormBuilder], { stepper: [0, "stepper"], claimData: [1, "claimData"] }, { incidentDetailsForm: "incidentDetailsForm", incidentDetailsData: "incidentDetailsData", closeEvent: "closeEvent" }), (_l()(), i1.ɵeld(18, 0, null, null, 7, "mat-step", [], null, null, null, i2.View_MatStep_0, i2.RenderType_MatStep)), i1.ɵprd(6144, null, i6.ErrorStateMatcher, null, [i3.MatStep]), i1.ɵdid(20, 573440, [[6, 4]], 1, i3.MatStep, [i3.MatStepper, [1, i6.ErrorStateMatcher], [2, i7.STEPPER_GLOBAL_OPTIONS]], { stepControl: [0, "stepControl"] }, null), i1.ɵqud(335544320, 9, { stepLabel: 0 }), (_l()(), i1.ɵand(0, null, 0, 1, null, View_ClaimWizardComponent_2)), i1.ɵdid(23, 16384, [[9, 4]], 0, i3.MatStepLabel, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(24, 0, null, 0, 1, "lib-invoice-step", [], null, [[null, "invoiceForm"], [null, "receiptUploadData"], [null, "damagedScreenUploadData"], [null, "invoiceData"], [null, "invoiceStepHomeClickEvent"], [null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("invoiceForm" === en)) {
        var pd_0 = (_co.invoiceFormEventHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("receiptUploadData" === en)) {
        var pd_1 = (_co.uploadRepairReceipt($event) !== false);
        ad = (pd_1 && ad);
    } if (("damagedScreenUploadData" === en)) {
        var pd_2 = (_co.uploadDmagedScreen($event) !== false);
        ad = (pd_2 && ad);
    } if (("invoiceData" === en)) {
        var pd_3 = (_co.setInvoiceData($event) !== false);
        ad = (pd_3 && ad);
    } if (("invoiceStepHomeClickEvent" === en)) {
        var pd_4 = (_co.invoiceStepHomeClick() !== false);
        ad = (pd_4 && ad);
    } if (("closeEvent" === en)) {
        var pd_5 = (_co.closeClaimWiz() !== false);
        ad = (pd_5 && ad);
    } return ad; }, i11.View_CbfwInvoiceStepComponent_0, i11.RenderType_CbfwInvoiceStepComponent)), i1.ɵdid(25, 114688, [[1, 4]], 0, i12.CbfwInvoiceStepComponent, [i10.FormBuilder, i13.Router], { stepper: [0, "stepper"], claimData: [1, "claimData"] }, { invoiceForm: "invoiceForm", receiptUploadData: "receiptUploadData", damagedScreenUploadData: "damagedScreenUploadData", invoiceData: "invoiceData", invoiceStepHomeClickEvent: "invoiceStepHomeClickEvent", closeEvent: "closeEvent" }), (_l()(), i1.ɵeld(26, 0, null, null, 7, "mat-step", [], null, null, null, i2.View_MatStep_0, i2.RenderType_MatStep)), i1.ɵprd(6144, null, i6.ErrorStateMatcher, null, [i3.MatStep]), i1.ɵdid(28, 573440, [[6, 4]], 1, i3.MatStep, [i3.MatStepper, [1, i6.ErrorStateMatcher], [2, i7.STEPPER_GLOBAL_OPTIONS]], { stepControl: [0, "stepControl"] }, null), i1.ɵqud(335544320, 10, { stepLabel: 0 }), (_l()(), i1.ɵand(0, null, 0, 1, null, View_ClaimWizardComponent_3)), i1.ɵdid(31, 16384, [[10, 4]], 0, i3.MatStepLabel, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(32, 0, null, 0, 1, "lib-check-delivery-step", [], null, [[null, "checkDeliveryForm"], [null, "checkDeliveryData"], [null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkDeliveryForm" === en)) {
        var pd_0 = (_co.checkDeliveryFormEventHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("checkDeliveryData" === en)) {
        var pd_1 = (_co.setCheckDeliveryData($event) !== false);
        ad = (pd_1 && ad);
    } if (("closeEvent" === en)) {
        var pd_2 = (_co.closeClaimWiz() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i14.View_CbfwCheckDeliveryStepComponent_0, i14.RenderType_CbfwCheckDeliveryStepComponent)), i1.ɵdid(33, 114688, [[2, 4]], 0, i15.CbfwCheckDeliveryStepComponent, [i10.FormBuilder], { stepper: [0, "stepper"], claimData: [1, "claimData"] }, { checkDeliveryForm: "checkDeliveryForm", checkDeliveryData: "checkDeliveryData", closeEvent: "closeEvent" }), (_l()(), i1.ɵeld(34, 0, null, null, 7, "mat-step", [], null, null, null, i2.View_MatStep_0, i2.RenderType_MatStep)), i1.ɵprd(6144, null, i6.ErrorStateMatcher, null, [i3.MatStep]), i1.ɵdid(36, 573440, [[6, 4]], 1, i3.MatStep, [i3.MatStepper, [1, i6.ErrorStateMatcher], [2, i7.STEPPER_GLOBAL_OPTIONS]], { stepControl: [0, "stepControl"] }, null), i1.ɵqud(335544320, 11, { stepLabel: 0 }), (_l()(), i1.ɵand(0, null, 0, 1, null, View_ClaimWizardComponent_4)), i1.ɵdid(39, 16384, [[11, 4]], 0, i3.MatStepLabel, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(40, 0, null, 0, 1, "lib-claim-summary-step", [], null, [[null, "claimSummaryForm"], [null, "claimSubmit"], [null, "closeEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("claimSummaryForm" === en)) {
        var pd_0 = (_co.claimSummaryFormEventHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("claimSubmit" === en)) {
        var pd_1 = (_co.submitClaimData($event) !== false);
        ad = (pd_1 && ad);
    } if (("closeEvent" === en)) {
        var pd_2 = (_co.closeClaimWiz() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i16.View_CbfwClaimSummaryStepComponent_0, i16.RenderType_CbfwClaimSummaryStepComponent)), i1.ɵdid(41, 114688, [[3, 4]], 0, i17.CbfwClaimSummaryStepComponent, [i10.FormBuilder, i13.Router], { stepper: [0, "stepper"], claimData: [1, "claimData"] }, { claimSummaryForm: "claimSummaryForm", claimSubmit: "claimSubmit", closeEvent: "closeEvent" }), (_l()(), i1.ɵeld(42, 0, null, null, 1, "lib-close-warning-modal-popup", [], null, [[null, "yesEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("yesEvent" === en)) {
        var pd_0 = (_co.clainWizardEnd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i18.View_CbfwCloseWarningModalPopupComponent_0, i18.RenderType_CbfwCloseWarningModalPopupComponent)), i1.ɵdid(43, 49152, [[5, 4]], 0, i19.CbfwCloseWarningModalPopupComponent, [], null, { yesEvent: "yesEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isLinear; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.incidentDetailsFormGroup; _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 6); var currVal_5 = _co.claimData; _ck(_v, 17, 0, currVal_4, currVal_5); var currVal_6 = _co.invoiceFormGroup; _ck(_v, 20, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 6); var currVal_8 = _co.claimData; _ck(_v, 25, 0, currVal_7, currVal_8); var currVal_9 = _co.checkDeliveryFormGroup; _ck(_v, 28, 0, currVal_9); var currVal_10 = i1.ɵnov(_v, 6); var currVal_11 = _co.claimData; _ck(_v, 33, 0, currVal_10, currVal_11); var currVal_12 = _co.claimSummaryFormGroup; _ck(_v, 36, 0, currVal_12); var currVal_13 = i1.ɵnov(_v, 6); var currVal_14 = _co.claimData; _ck(_v, 41, 0, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 6).labelPosition == "end"); var currVal_1 = (i1.ɵnov(_v, 6).labelPosition == "bottom"); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_ClaimWizardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-claim-wizard", [], null, null, null, View_ClaimWizardComponent_0, RenderType_ClaimWizardComponent)), i1.ɵdid(1, 114688, null, 0, i20.ClaimWizardComponent, [i10.FormBuilder, i13.Router, i21.CbfwConfirmDialogService, i22.Ng4LoadingSpinnerService, i23.StateService, i24.EnvConfig, i25.ClaimDataManagementService, i26.InvoiceService, i27.ClaimService, i28.CheckDeliveryService, i29.AppDeviceService, i30.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClaimWizardComponentNgFactory = i1.ɵccf("app-claim-wizard", i20.ClaimWizardComponent, View_ClaimWizardComponent_Host_0, {}, {}, []);
export { ClaimWizardComponentNgFactory as ClaimWizardComponentNgFactory };
