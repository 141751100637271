import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
// import 'rxjs/add/operator/toPromise';

import { State } from '../models/state';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { EnvConfig } from '../services/app-env-config';


const httpOptionst = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()
export class StateService {

  private handleError: HandleError;
  private apiUrlMaster;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private envConfig: EnvConfig) {

    // pass this class name here;
    this.handleError = httpErrorHandler.createHandleError('StateService');

    this.apiUrlMaster = this.envConfig.get('apiUrlMaster');
  }


  getStates(): Observable<State[]> {

    return this.http.get<State[]>(this.apiUrlMaster + '/State/GetAllStates')
      .pipe(
        catchError(this.handleError('getStates', []))
      );
  }

}
