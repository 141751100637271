import { EnvConfig } from './shared/services/app-env-config';
import { ConfigService } from './shared/services/config.service';
var appInitializerFn = function (appConfig) {
    return function () {
        return appConfig.loadConfig();
    };
};
var ɵ0 = appInitializerFn;
var ɵ1 = { hasBackdrop: false }, ɵ2 = function () { return EnvConfig.getInstance('../assets/env-config.json'); };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
