import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CbfwConfirmationinfoStepComponent } from 'cbfw-confirmationinfo-step';
import { Constants } from 'src/app/shared/utilities/constants';

@Component({
  selector: 'app-enroll-confirmation',
  templateUrl: './enroll-confirmation.component.html',
  styleUrls: ['./enroll-confirmation.component.css']
})
export class EnrollConfirmationComponent implements OnInit {


  @ViewChild(CbfwConfirmationinfoStepComponent) confirmationinfoStepComponent: CbfwConfirmationinfoStepComponent;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.confirmationinfoStepComponent.SuccessMessageText.DisplayValue = Constants.enrollmentConfirmationMessage;

    this.confirmationinfoStepComponent.SummaryMessageText.DisplayValue = Constants.enrollmentSummaryText;

    this.confirmationinfoStepComponent.FailMessageText.DisplayValue = Constants.enrollmentConfirmationFailMessage;
  }

  navigateToHome() {
    this.router.navigate(['./home']);
  }

}
