import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { EnvConfig } from '../../services/app-env-config';
import { AppSettings } from '../../models/app-settings';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CbfwContactUsComponent, Controller } from 'cbfw-contact-us';
import { ViewChild } from '@angular/core';
import { ContactUsService } from '../../services/contact-us.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HtmlConstants } from '../../utilities/html-constants';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  public modalContactUsTitle: Controller = {
    IsVisible: true,
    DisplayValue: 'CONTACT US'
  };
  public modalContactUsMessage: Controller = {
    IsVisible: true,
    DisplayValue: HtmlConstants.modalContactUsMessage
  };
  public modalContactUsName: Controller = {
    IsVisible: true,
    DisplayValue: 'Name'
  };
  public modalContactUsNameErrorRequired: Controller = {
    IsVisible: true,
    DisplayValue: `Name is
    <strong>required.</strong>`
  };
  public modalContactUsEmailAddress: Controller = {
    IsVisible: true,
    DisplayValue: 'Email Address'
  };
  public modalContactUsEmailAddressErrorRequired: Controller = {
    IsVisible: true,
    DisplayValue: `Email is
    <strong>required.</strong>`
  };
  public modalContactUsEmailAddressErrorPattern: Controller = {
    IsVisible: true,
    DisplayValue: `Should be a valid
    <strong>email.</strong>`
  };
  public modalContactUsPhoneNumber: Controller = {
    IsVisible: true,
    DisplayValue: 'Phone Number'
  };
  public modalContactUsErrorPhoneNumberRequired: Controller = {
    IsVisible: true,
    DisplayValue: `Phone Number is
    <strong>required.</strong>`
  };
  public modalContactUsHintPhoneNumber: Controller = {
    IsVisible: true,
    DisplayValue: '/ 10'
  };
  public modalContactUsErrorPhoneNumberInvalid: Controller = {
    IsVisible: true,
    DisplayValue: 'Invalid phone number.'
  };
  public modalContactUsComment: Controller = {
    IsVisible: true,
    DisplayValue: 'Leave a comment'
  };
  public modalContactUsErrorCommentRequired: Controller = {
    IsVisible: true,
    DisplayValue: `Comment field is
    <strong>required.</strong>`
  };
  public modalContactUsSuccessMessage: Controller = {
    IsVisible: true,
    DisplayValue: 'Email has been sent successfully!'
  };
  public modalContactUsErrorMessage: Controller = {
    IsVisible: true,
    DisplayValue: 'An error occurred while sending the email!'
  };
  public modalContactUsButtonEmail: Controller = {
    IsVisible: true,
    DisplayValue: 'Email'
  };
  public modalContactUsButtonClose: Controller = {
    IsVisible: true,
    DisplayValue: 'Close'
  };

  showEmialContent = 'none';
  showSuccessMsg = false;
  showErrorMsg = false;

  public contactUsFormGroup: FormGroup;

  nameCtrl: FormControl = new FormControl('', Validators.required);
  emailCtrl: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)]);
  phoneNumberCtrl: FormControl = new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]);
  commentCtrl: FormControl = new FormControl('', Validators.required);

  contactUsSettings: any;


  settings: AppSettings;
  corporateEntityId;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private envConfig: EnvConfig,
    private contactUsService: ContactUsService,
    private configService: ConfigService,
    private _formBuilder: FormBuilder
  ) {

    this.corporateEntityId = this.envConfig.get('corporateEntityId');
    this.settings = this.configService.config;
    this.contactUsSettings = this.settings.contactUs;

  }

  ngOnInit() {
    this.contactUsFormGroup = this._formBuilder.group({
      nameCtrl: this.nameCtrl,
      emailCtrl: this.emailCtrl,
      phoneNumberCtrl: this.phoneNumberCtrl,
      commentCtrl: this.commentCtrl
    });
  }

  sendEmail() {

    if (this.showEmialContent === 'none') {
      this.showEmialContent = 'block';
    } else {
      this.submitContactDtata();
    }

  }

  closeModal() {
    this.showEmialContent = 'none';
    this.showSuccessMsg = false;
    this.showErrorMsg = false;
    this.resetForm();
  }

  submitContactDtata() {

    Object.keys(this.contactUsFormGroup.controls).forEach(field => {
      const control = this.contactUsFormGroup.get(field);
      control.markAsTouched({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true });
    });

    this.showSuccessMsg = false;
    this.showErrorMsg = false;

    if (this.contactUsFormGroup.valid) {
      this.contactUsSubmit(this.contactUsFormGroup.value);
    }
  }

  resetForm() {
    this.contactUsFormGroup.reset();
  }

  contactUsSubmit(formGroup) {
    const postData: any = {};
    const contacData: any = {};

    contacData.Name = formGroup.nameCtrl;
    contacData.EMail = formGroup.emailCtrl;
    contacData.PhoneNumber = formGroup.phoneNumberCtrl;
    contacData.Comments = formGroup.commentCtrl;
    contacData.ToAddress = this.contactUsSettings.toAddress;
    contacData.FromAddress = this.contactUsSettings.fromAddress;
    contacData.Subject = this.contactUsSettings.emailSubject;
    contacData.CorporateEntityId = this.corporateEntityId;

    postData.ContactUs = contacData;

    this.spinnerService.show();
    this.contactUsService.postContactInformation(postData)
      .subscribe(res => {
        if (res === 1) {
          this.showSuccessMsg = true;
          this.resetForm();
        } else {
          this.showErrorMsg = true;
        }

        this.spinnerService.hide();
      });
  }

}
