import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticateService } from '././authenticate.service';
import { EnvConfig } from './app-env-config';



@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthenticateService,
    private envConfig: EnvConfig
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const authMasterUrl = this.envConfig.get('apiUrlMaster') + '/Authenticate/RequestTokenV3';

    if (request.url !== './assets/app-config.json' && request.url !== authMasterUrl) {

      const isValidToken = this.authService.isValidToken(sessionStorage.getItem('requestToken'));

      if (isValidToken === true) {
        request = request.clone({
          headers: new HttpHeaders()
            .set('Authorization', 'Bearer ' + sessionStorage.getItem('requestToken'))
        });
      } else {

        // get, set silently request Token
        this.authService.userAuthenticationMaster().subscribe(res => {
          if (res.status === true) {
            sessionStorage.setItem('requestToken', res.result);
            // request = request.clone({
            //   headers: new HttpHeaders()
            //     .set('Authorization', 'Bearer ' + localStorage.getItem('requestToken'))
            // });
          }
        });
      }
    }


    return next.handle(request)
      .pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // redirect to the login route
            // or show a modal
            // this.route.navigate(['login']);

            // get access token
            // by refresh token silently
          }
        }
      }));
  }
}
