import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { EnvConfig } from './app/shared/services/app-env-config';

if (environment.production) {
  enableProdMode();
}

EnvConfig.loadInstance('../assets/env-config.json')
  .then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
