import { Component, OnInit, ViewChild } from '@angular/core';
import { ClaimDataManagementService } from '../../services/claim-data-management.service';
import { ClaimData } from '../../models/claim-data.model';
import { Router } from '@angular/router';
import { ClaimService } from '../../services/claim.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Constants } from '../../../shared/utilities/constants';
import { CbfwPolicyDetailsComponent } from 'cbfw-policy-details';
import { AppDeviceService } from '../../../shared/services/app-device.service';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { HtmlConstants } from 'src/app/shared/utilities/html-constants';

declare let $: any;

@Component({
  selector: 'app-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.css']
})
export class PolicyDetailsComponent implements OnInit {
  policyData: any;
  appDevice: string;

  @ViewChild(CbfwPolicyDetailsComponent) cbfwPolicyDetailsComponent: CbfwPolicyDetailsComponent;

  constructor(
    private claimDataManagementService: ClaimDataManagementService,
    private router: Router,
    private claimService: ClaimService,
    private spinnerService: Ng4LoadingSpinnerService,
    private appDeviceService: AppDeviceService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.claimDataManagementService.ClaimData.subscribe(data => {
      if (data.PolicyData) {
        this.policyData = data.PolicyData;
      } else {
        this.router.navigate([Constants.policyLookupRoute]);
      }
    });

    this.cbfwPolicyDetailsComponent.lableNote.DisplayValue = HtmlConstants.cbfwPolicyDetailsComponent;

   this.cbfwPolicyDetailsComponent.modalUnsettledClaimContent.DisplayValue = HtmlConstants.policyUnsettledClaimContent;

   // Modification according to the user story 4973 | 2020-05-13
   this.cbfwPolicyDetailsComponent.textBoxSerialNumber.IsVisible = Constants.showImeiField;


  }

  CheckValidityForFileClaim(event) {
    this.spinnerService.show();
    this.claimService.checkValidityForFileClaim(event.customerId, event.policyId)
      .subscribe(res => {
        this.spinnerService.hide();

        if (res.SuccessIndicator === 2) {
          this.cbfwPolicyDetailsComponent.showUnsettledClaimModal();
        } else {
          this.router.navigate([Constants.claimFilingRoute]);
        }
      });
  }

  /// cancel click event
  cancelClick() {
    const appDevice = this.localStorageService.getItem(Constants.appDeviceKey);
    if (this.appDeviceService.isAppDeviceAndroidOrIos(appDevice)) {
      this.router.navigate([Constants.policyLookupRoute]);
    } else {
      this.router.navigate([Constants.homeRoute]);
    }
  }
}
