import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Constants } from 'src/app/shared/utilities/constants';

@Injectable()
export class EnrollmentGuard implements CanActivate {
  constructor(public router: Router) {}
  canActivate(): boolean {
    if (!Constants.allowRouteToEnrollment) {
      this.router.navigate([Constants.homeRoute]);
      return false;
    }
    return true;
  }
}
