import { Component, OnInit, ViewChild } from '@angular/core';
import { ClaimData } from '../../models/claim-data.model';
import { EnvConfig } from '../../../shared/services/app-env-config';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ClaimDataManagementService } from '../../services/claim-data-management.service';
import { PolicyLookupService } from '../../services/policy-lookup.service';
import { AppSettings } from '../../../shared/models/app-settings';
import { AppSettingsService } from '../../../shared/services/app-settings-service';
import { Router, ActivatedRoute } from '@angular/router';
import { CbfwPolicyLookupComponent } from 'cbfw-policy-lookup';
import { Controller } from 'cbfw-policy-lookup';
import { Constants } from '../../../shared/utilities/constants';
import { FormControl } from '@angular/forms';
import { HtmlConstants } from '../../../shared/utilities/html-constants';
import { AppDeviceService } from '../../../shared/services/app-device.service';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { Title } from '@angular/platform-browser';
import { ItemModel } from 'cbfw-collapsable-modal-popup';

declare let $: any;

@Component({
  selector: 'app-policy-lookup',
  templateUrl: './policy-lookup.component.html',
  styleUrls: ['./policy-lookup.component.css']
})
export class PolicyLookupComponent implements OnInit {

  ClaimData: ClaimData = new ClaimData;
  Item: ItemModel = new ItemModel;
  index: number;
  searchPolicyResponse: Array<any>;
  policyLookupDate: any;

  appId;
  maincategoryId;
  subcategoryId;
  corporateEntityId;
  claimType;
  incidentData: any = {};
  policyLookupConfig: any;
  settings: AppSettings;
  searchItems: ItemModel[];

  @ViewChild(CbfwPolicyLookupComponent) cbfwPolicyLookupComponent: CbfwPolicyLookupComponent;

  constructor(
    private envConfig: EnvConfig,
    private policyLookupService: PolicyLookupService,
    private spinnerService: Ng4LoadingSpinnerService,
    private claimDataManagementService: ClaimDataManagementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appDeviceService: AppDeviceService,
    private localStorageService: LocalStorageService,
    private titleService: Title
  ) {

    this.appId = this.envConfig.get('appId');
    this.corporateEntityId = this.envConfig.get('corporateEntityId');
    this.maincategoryId = this.envConfig.get('maincategoryId');
    this.subcategoryId = this.envConfig.get('subcategoryId');
    this.claimType = this.envConfig.get('claimType');
    this.incidentData.IncidentType = this.envConfig.get('incidentType');
    this.incidentData.IncidentTypeText = this.envConfig.get('incidentTypeText');
    this.titleService.setTitle(Constants.siteName + ' | File a Claim');
    // this.incidentData.IncidentTypeText = 'Physical Damage';

  }

  ngOnInit() {

    this.cbfwPolicyLookupComponent.searchItems = Constants.policyLookupSearchItems;

    this.cbfwPolicyLookupComponent.textBoxDefault.DisplayValue = Constants.policyLookupTextBoxDefaultValue;

    this.cbfwPolicyLookupComponent.labelDeductibleReceipt.DisplayValue = Constants.labelDeductibleReceiptText;

    this.cbfwPolicyLookupComponent.radioButtonRegisteredProduct = {
      IsVisible: true, DisplayValue: Constants.radioButtonRegisteredProductLabel
    };

    this.cbfwPolicyLookupComponent.modalTermsAndConditionsMessage.DisplayValue =
      HtmlConstants.modalTermsAndConditionsMessage;

    if (HtmlConstants.modalTermsAndConditionsTitle !== undefined) {
      this.cbfwPolicyLookupComponent.modalTermsAndConditionsTitle.DisplayValue =
      HtmlConstants.modalTermsAndConditionsTitle;
    }

    this.cbfwPolicyLookupComponent.labelPolicyError.DisplayValue = HtmlConstants.policyLookupErrorMessage;
    this.cbfwPolicyLookupComponent.radioButtonDeductibleReceipt.DisplayValue = Constants.claimDeductableReceiptText;

    this.cbfwPolicyLookupComponent.labelNote.DisplayValue = HtmlConstants.searchPolicyComponentNote;

    this.cbfwPolicyLookupComponent.labelRegisteredProduct.DisplayValue = Constants.labelRegisteredProduct;

    this.cbfwPolicyLookupComponent.textBoxEmail.DisplayValue = Constants.textBoxEmailLabel;
    this.cbfwPolicyLookupComponent.lookuplNotice.IsVisible = Constants.policyLookuplNoticeVisisble;
    this.cbfwPolicyLookupComponent.lookuplNotice.DisplayValue = HtmlConstants.lookuplNotice;

    this.activatedRoute.queryParams.subscribe(params => {
      const appDevice = params[Constants.appDeviceKey];
      const appDeviceLocalStorage = this.localStorageService.getItem(Constants.appDeviceKey);

      if (this.appDeviceService.isAppDeviceAndroidOrIos(appDevice) ||
        this.appDeviceService.isAppDeviceAndroidOrIos(appDeviceLocalStorage)) {
        this.cbfwPolicyLookupComponent.buttonCancel.IsVisible = false;
      } else {
        this.cbfwPolicyLookupComponent.buttonCancel.IsVisible = true;
      }
    });

    $('body').css('margin-bottom', '180px');

  }




  searchPolicy(event) {
    this.spinnerService.show();
    this.policyLookupService.getPolicy(
      event.searchVal, event.email, event.searchBy, this.appId, this.maincategoryId, this.subcategoryId)
      .subscribe(res => {
        if (res.length > 0) {
          if (res.length === 1) {
            this.ClaimData.PolicyData = res[0];
            this.ClaimData.PolicyLookUpData = event;
            this.ClaimData.ClaimType = this.claimType;
            this.ClaimData.AppId = this.appId;
            this.ClaimData.CorporateEntityId = res[0].PolicyCorporateEntityId;
            localStorage.setItem('corporateEntityId', res[0].PolicyCorporateEntityId);
            this.ClaimData.IncidentData = this.incidentData;
            this.claimDataManagementService.setClaimData(this.ClaimData);
            this.cbfwPolicyLookupComponent.showPolicyError = false;
            this.router.navigate([Constants.policyDetailsRoute]);
          } else {
            this.searchPolicyResponse = res;
            this.createMultiplePolicyArray(res);
            this.policyLookupDate = event;
            this.cbfwPolicyLookupComponent.modalSearchPolicyMessage.DisplayValue = Constants.modalSearchPolicyMessage;
            this.cbfwPolicyLookupComponent.modalContactInfoMessage.DisplayValue = Constants.modalContactInfoMessage;
            this.cbfwPolicyLookupComponent.modalMultiplePolicySearchItems = this.searchItems;
            this.cbfwPolicyLookupComponent.showMultiplePolicyModal();
          }
        } else {
          this.cbfwPolicyLookupComponent.showPolicyError = true;
        }
        this.spinnerService.hide();
      });
  }

  createMultiplePolicyArray(response: Array<any>) {
    this.searchItems = [];
    this.index = 0;
    response.forEach((x) => {
      this.Item.id = this.index++;
      this.Item.corporateEntity = 'Corporate Entity Name';
      this.Item.corporateEntityId = x.PolicyCorporateEntityId ? x.PolicyCorporateEntityId : 0;
      this.Item.policyNumber = x.PolicyNumber ? x.PolicyNumber : '';
      this.Item.dateEnrolled = x.DateIssue ? x.DateIssue : '';
      this.Item.deviceModel = x.ProductModel ? x.ProductModel : '';
      this.Item.esnOrSerialNumber = x.ESN ? x.ESN : '';
      this.Item.accessory = x.CaseModelDescription ? x.CaseModelDescription : '';
      this.Item.registrationCode = x.ActivationCode ? x.ActivationCode : '';
      this.searchItems.push(this.Item);
      this.Item = new ItemModel;
    });
  }

  selectPolicy(event) {

    this.ClaimData.PolicyData = this.searchPolicyResponse.find(x => x.PolicyNumber === event.policyNumber);
    this.ClaimData.PolicyLookUpData = this.policyLookupDate;
    this.ClaimData.ClaimType = this.claimType;
    this.ClaimData.AppId = this.appId;
    this.ClaimData.CorporateEntityId = event.corporateEntityId;

    localStorage.setItem('corporateEntityId', event.corporateEntityId);

    this.ClaimData.IncidentData = this.incidentData;
    this.claimDataManagementService.setClaimData(this.ClaimData);
    this.cbfwPolicyLookupComponent.showPolicyError = false;
    this.router.navigate([Constants.policyDetailsRoute]);
    this.closeModal('mulitple-policy-modal');

  }

  closeModal(modalId: string) {
    const popUpId = $('#' + modalId);
    popUpId.modal('hide');
  }

  cancelClick() {
    this.router.navigate([Constants.homeRoute]);
  }
}
