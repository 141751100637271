<div class="container-fluid">
        <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                        <!-- modal content-->
                        <div class="terms-wrapper">
                                        <h3 class="p-20">Contact Us</h3>
                                <div class="single-section-main">
                                        <div class="single-section" *ngIf="modalContactUsMessage.IsVisible" [innerHTML]="modalContactUsMessage.DisplayValue">
                                        </div>
                                </div>
                                <div class="emailSec" data-color="blue" [ngStyle]="{'display':showEmialContent}">
                                        <form class="example-form" [formGroup]="contactUsFormGroup">
                                                <div>
                                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                                                
                                                                <!-- <div class="form-group label-floating" data-color="blue">
                                  <label class="control-label">Name
                                    <small>(required) *</small>
                                  </label>
                                  <input type="text" name="name" class="form-control">
                                </div> -->
                                                                <mat-form-field class="example-full-width" *ngIf="modalContactUsName.IsVisible">
                                                                        <input matInput [placeholder]="modalContactUsName.DisplayValue" formControlName="nameCtrl" required id="txtName">
                                                                        <mat-error *ngIf="nameCtrl.hasError('required') && modalContactUsNameErrorRequired.IsVisible" [innerHTML]="modalContactUsNameErrorRequired.DisplayValue">
                                                                        </mat-error>
                                                                </mat-form-field>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <!-- <div class="form-group label-floating">
                                  <label class="control-label">Email Address
                                    <small>(required) *</small>
                                  </label>
                                  <input type="email" name="email" class="form-control">
            
                                </div> -->
                                                                <mat-form-field class="example-full-width" *ngIf="modalContactUsEmailAddress.IsVisible">
                                                                        <input type="email" matInput [placeholder]="modalContactUsEmailAddress.DisplayValue" formControlName="emailCtrl" required
                                                                                id="txtEmail">
                                                                        <mat-error *ngIf="emailCtrl.hasError('required') && modalContactUsEmailAddressErrorRequired.IsVisible" [innerHTML]="modalContactUsEmailAddressErrorRequired.DisplayValue">
                                                                        </mat-error>
                                                                        <mat-error *ngIf="emailCtrl.hasError('pattern') && !emailCtrl.hasError('required') && modalContactUsEmailAddressErrorPattern.IsVisible"
                                                                                [innerHTML]="modalContactUsEmailAddressErrorPattern.DisplayValue">
                                                                        </mat-error>
                                                                </mat-form-field>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <!-- <div class="form-group label-floating">
                                  <label class="control-label">Phone Number
                                    <small>(required) *</small>
                                  </label>
                                  <input type="email" name="email" class="form-control">
                                </div> -->
                                                                <mat-form-field class="example-full-width" *ngIf="modalContactUsPhoneNumber.IsVisible">
                                                                        <input #phoneNumber matInput [placeholder]="modalContactUsPhoneNumber.DisplayValue" formControlName="phoneNumberCtrl" maxlength="10"
                                                                                required id="txtPhone">
                                                                        <mat-hint align="end">{{phoneNumber.value.length}} {{modalContactUsHintPhoneNumber.DisplayValue}}</mat-hint>
                                                                        <mat-error *ngIf="phoneNumberCtrl.hasError('required') && modalContactUsErrorPhoneNumberRequired.IsVisible" [innerHTML]="modalContactUsErrorPhoneNumberRequired.DisplayValue">
                                                                        </mat-error>
                                                                        <mat-error *ngIf="phoneNumberCtrl.hasError('pattern') && !phoneNumberCtrl.hasError('required') && modalContactUsErrorPhoneNumberInvalid.IsVisible"
                                                                                [innerHTML]="modalContactUsErrorPhoneNumberInvalid.DisplayValue">
                                                                        </mat-error>
                                                                </mat-form-field>
                                                                <mat-form-field class="example-full-width" *ngIf="modalContactUsComment.IsVisible">
                                                                        <textarea matInput [placeholder]="modalContactUsComment.DisplayValue" formControlName="commentCtrl" required id="txtComment"></textarea>
                                                                        <mat-error *ngIf="commentCtrl.hasError('required') && modalContactUsErrorCommentRequired.IsVisible" [innerHTML]="modalContactUsErrorCommentRequired.DisplayValue">
                                                                        </mat-error>
                                                                </mat-form-field>
                                                        </div>
                                                </div>
                                        </form>
                                </div>
                        </div>
                        <!-- Modal Content End-->
                </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12">
                <div *ngIf="showSuccessMsg && modalContactUsSuccessMessage.IsVisible" class="alert alert-success text-center" role="alert">
                        {{modalContactUsSuccessMessage.DisplayValue}}
                </div>
                <div *ngIf="showErrorMsg && modalContactUsErrorMessage.IsVisible" class="alert alert-danger text-center" role="alert">
                        {{modalContactUsErrorMessage.DisplayValue}}
                </div>
        </div>

        <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                        <button *ngIf="modalContactUsButtonEmail.IsVisible" type="button" id="Emailbtn" class="btn btn-primary btn-cust-con" (click)="sendEmail()">{{modalContactUsButtonEmail.DisplayValue}}</button>
                        <button *ngIf="modalContactUsButtonClose.IsVisible" type="button" id="btnClose" class="btn btn-primary btn-cust-con" data-dismiss="modal"
                                (click)="closeModal()">{{modalContactUsButtonClose.DisplayValue}}</button>
                </div>
        </div>
</div>