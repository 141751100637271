import { Component, OnInit } from '@angular/core';
import { HtmlConstants } from '../../utilities/html-constants';
import { AppDeviceService } from '../../services/app-device.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  termsAndConditions = '';

  constructor(private appDeviceService: AppDeviceService) {
  }

  ngOnInit() {
    this.termsAndConditions = HtmlConstants.modalTermsAndConditionsMessage;
    // this.appDeviceService.getAppDeviceValueAndHideFotterAndHeader();
  }

}
