<!-- <lib-claim-stepper 

[stepperLib]="stepper"
[claimDataLib]="claimData"
[isLinearLib]="isLinear" 

[incidentDetailsFormGroup]="incidentDetailsFormGroup"
[invoiceFormGroup]="invoiceFormGroup"
[checkDeliveryFormGroup]="checkDeliveryFormGroup"
[claimSummaryFormGroup]="claimSummaryFormGroup"

(incidentDetailsForm)="incidentDetailsFormEventHandler($event)"
(incidentDetailsData)="setIncidentDetailsData($event)" 

(invoiceForm)="invoiceFormEventHandler($event)" 
(receiptUploadData)="uploadRepairReceipt($event)"
(damagedScreenUploadData)="uploadDmagedScreen($event)" 
(invoiceData)="setInvoiceData($event)" 
(invoiceStepHomeClickEvent)="invoiceStepHomeClick()" 

(checkDeliveryForm)="checkDeliveryFormEventHandler($event)"
(checkDeliveryData)="setCheckDeliveryData($event)"

(claimSummaryForm)="claimSummaryFormEventHandler($event)"
(claimSubmit)="submitClaimData($event)" 
(confirmation)="summaryGoToConfirmation()"
(closeEvent)="closeClaimWiz()"

(yesEvent)="clainWizardEnd()"
></lib-claim-stepper> -->

<mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper">
  <mat-step [stepControl]="incidentDetailsFormGroup">
    <ng-template matStepLabel>
      <span class="hide-text">Incident Details</span>
    </ng-template>
    <lib-incident-details-step [stepper]="stepper" [claimData]="claimData" (incidentDetailsForm)="incidentDetailsFormEventHandler($event)"
      (incidentDetailsData)="setIncidentDetailsData($event)" (closeEvent)="closeClaimWiz()">
    </lib-incident-details-step>
  </mat-step>

  <mat-step [stepControl]="invoiceFormGroup">
    <ng-template matStepLabel>
      <span class="hide-text">Invoice</span>
    </ng-template>
    <lib-invoice-step [stepper]="stepper" [claimData]="claimData" (invoiceForm)="invoiceFormEventHandler($event)" (receiptUploadData)="uploadRepairReceipt($event)"
      (damagedScreenUploadData)="uploadDmagedScreen($event)" (invoiceData)="setInvoiceData($event)" (invoiceStepHomeClickEvent)="invoiceStepHomeClick()"
      (closeEvent)="closeClaimWiz()"></lib-invoice-step>
  </mat-step>

  <mat-step [stepControl]="checkDeliveryFormGroup">
    <ng-template matStepLabel>
      <span class="hide-text" [innerHTML]="steperItemThree">Check Delivery</span>
    </ng-template>
    <lib-check-delivery-step [stepper]="stepper" [claimData]="claimData" (checkDeliveryForm)="checkDeliveryFormEventHandler($event)"
      (checkDeliveryData)="setCheckDeliveryData($event)" (closeEvent)="closeClaimWiz()"></lib-check-delivery-step>
  </mat-step>

  <mat-step [stepControl]="claimSummaryFormGroup">
    <ng-template matStepLabel>
      <span class="hide-text">Summary</span>
    </ng-template>
    <lib-claim-summary-step [stepper]="stepper" [claimData]="claimData" (claimSummaryForm)="claimSummaryFormEventHandler($event)"
      (claimSubmit)="submitClaimData($event)" (closeEvent)="closeClaimWiz()"></lib-claim-summary-step>
  </mat-step>

</mat-horizontal-stepper>

<lib-close-warning-modal-popup (yesEvent)="clainWizardEnd()"></lib-close-warning-modal-popup>
