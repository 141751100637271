import { Constants } from '../utilities/constants';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./local-storage.service";
var AppDeviceService = /** @class */ (function () {
    function AppDeviceService(localStorageService) {
        this.localStorageService = localStorageService;
    }
    /// get app_device value and hide fotter and header
    AppDeviceService.prototype.getAppDeviceValueAndHideFotterAndHeader = function () {
        var appDevice = this.localStorageService.getItem(Constants.appDeviceKey);
        this.hideFotterAndHeader(appDevice);
    };
    /// hide fotter and header
    AppDeviceService.prototype.hideFotterAndHeader = function (appDevice) {
        if (this.isAppDeviceAndroidOrIos(appDevice)) {
            this.localStorageService.setItem(Constants.appDeviceKey, appDevice);
            $('footer').hide();
            $('header').hide();
            //// to hide footer and header nav padding
            $('body').attr('style', 'padding-top: 0px !important; margin-bottom: 10px !important;');
        }
    };
    /// is app_device android or ios
    AppDeviceService.prototype.isAppDeviceAndroidOrIos = function (appDevice) {
        return appDevice && (appDevice.toLocaleLowerCase() === 'ios' || appDevice.toLocaleLowerCase() === 'android');
    };
    AppDeviceService.ngInjectableDef = i0.defineInjectable({ factory: function AppDeviceService_Factory() { return new AppDeviceService(i0.inject(i1.LocalStorageService)); }, token: AppDeviceService, providedIn: "root" });
    return AppDeviceService;
}());
export { AppDeviceService };
