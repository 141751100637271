import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../../shared/utilities/constants';
import { ViewChild } from '@angular/core';
import { CbfwEnrollmentDeviceTypeComponent } from 'cbfw-enrollment-device-type';
import { Title } from '@angular/platform-browser';
import { DeviceInfoService } from '../../../shared/services/device-info.service';

declare let $: any;

@Component({
  selector: 'app-enrollment-device-type',
  templateUrl: './enrollment-device-type.component.html',
  styleUrls: ['./enrollment-device-type.component.css']
})
export class EnrollmentDeviceTypeComponent implements OnInit, AfterViewInit {

  /// CbfwEnrollmentDeviceTypeComponent view child
  @ViewChild(CbfwEnrollmentDeviceTypeComponent) cbfwEnrollmentDeviceTypeComponent: CbfwEnrollmentDeviceTypeComponent;
 
  productCategoryList: any[] = [];

  private showCategoryListDropDownForIgr = true;

  constructor(
    private router: Router,
    private titleService: Title,
    private deviceInfoService: DeviceInfoService) {
    this.titleService.setTitle(Constants.siteName + ' | Device Enrollment');
    this.showCategoryListDropDownForIgr = Constants.showCategoryListDropDownForIgr;
  }

  /// cancel click
  cancelClick() {
    this.router.navigate([Constants.homeRoute]);
  }

  ngOnInit() {
    this.cbfwEnrollmentDeviceTypeComponent.radioButtonDeviceTypeOther.DisplayValue =
      Constants.deviceEnrollmentLabelText;

      if (this.showCategoryListDropDownForIgr) {
        this.cbfwEnrollmentDeviceTypeComponent.showCategoryListDropDownForIgr = this.showCategoryListDropDownForIgr;
        // This is called only for IGR
        this.getDeviceCategoryList();
      }

  }

  ngAfterViewInit() {
    const x = $('.mat-radio-label-content');
    for (let i = 0; i < x.length; i++) {
      if (x[i].innerText === 'Other - Enrollment of devices other than mobile phones') {
        x[i].innerHTML = 'Other - <span class="text-danger">Enrollment of devices other than mobile phones</span>';
      }
    }
  }

  /// next click
  nextClick() {
    setTimeout(() => {
      if (this.cbfwEnrollmentDeviceTypeComponent.isMobile) {
        this.router.navigate([Constants.enrollmentMobileDeviceRoute]);
      } else {
        // If in IGR, save selected Product Category Id, for further use
        const selectedCategoryId: string = this.cbfwEnrollmentDeviceTypeComponent.selectedCategoryId.toString();
        if (Constants.showCategoryListDropDownForIgr) {
          sessionStorage.setItem(Constants.sessionProductCategoryVariableName,
                                  selectedCategoryId);
        }
        this.router.navigate([Constants.enrollmentEnrollRoute]);

      }
    }, 5);
  }

  // Get Device Category List
  getDeviceCategoryList () {
    this.deviceInfoService.getDeviceCategoryList().subscribe((categoryList: any[]) => {
      this.productCategoryList = categoryList;
    });
  }

}
