<div class="clear">
</div>
<div class="container-full page-ht">
  <mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper">
    <mat-step [stepControl]="registrationFormGroup" [editable]="isStepEditable">
      <ng-template matStepLabel>
        <span class="hide-text">
          Enter your
          <br> Registration number
        </span>
      </ng-template>
      <lib-registrationinfo-step [stepper]="stepper" *ngIf="inputDataRegistration" [inputData]="inputDataRegistration" [states]="states"
        [enrollmentData]="enrollmentData" (registrationForm)="registrationFormEventHandler($event)" (outputData)="validateRegistration($event)"
        (closeWizEvent)="wizCloseWarningMessagePopUp()"></lib-registrationinfo-step>
    </mat-step>
    <mat-step [stepControl]="customerFormGroup" [editable]="isStepEditable">
      <ng-template matStepLabel>
        <span class="hide-text">Personal details</span>
      </ng-template>
      <lib-customerinfo-step [stepper]="stepper" *ngIf="inputDataCustomer" [inputData]="inputDataCustomer" (customerForm)="customerFormEventHandler($event)"
        (customerEmail)="validateEmail($event)" (customerData)="setCustomerData($event)" (closeWizEvent)="wizCloseWarningMessagePopUp()"
        (addressSuggestionEvent)="loadSuggestedAddresses($event)" (addressSelectionEvent)="bindSelectedAddress()"></lib-customerinfo-step>
    </mat-step>
    <mat-step [stepControl]="deviceInfoFormGroup" [editable]="isStepEditable">
      <ng-template matStepLabel>
        <span class="hide-text">Device information</span>
      </ng-template>
      <lib-deviceinfo-step [stepper]="stepper" [inputData]="inputDataDevice" (deviceInfoForm)="deviceInfoFormEventHandler($event)"
        (cellularData)="getManufacturers($event)" (manufacturerData)="getGetModels($event)" (modelData)="getCarriers($event)"
        (deviceData)="setDeviceData($event)" (esnFileData)="uploadEsnScreenshot($event)" (invoiceFileData)="uploadInvoiceFile($event)"
        (deviceDefultStoresData)="getDefultState()" (closeWizEvent)="wizCloseWarningMessagePopUp()"></lib-deviceinfo-step>
    </mat-step>
    <mat-step [stepControl]="summaryFormGroup" editable="false">
      <ng-template matStepLabel>
        <span class="hide-text">Review</span>
      </ng-template>
      <lib-summaryinfo-step [stepper]="stepper" [inputData]="inputDataSummary" 
      (summaryForm)="summaryFormEventHandler($event)"
      (enrolmentData)="enroll($event)" (closeWizEvent)="wizCloseWarningMessagePopUp()"></lib-summaryinfo-step>
    </mat-step>

  </mat-horizontal-stepper>
</div>
<div class="clear"></div>
<div>
  <a href="#0" class="cd-top">Top</a>
</div>
<div class="clear"></div>

<lib-close-warning-modal-popup (yesEvent)="cancelEnrollment()"></lib-close-warning-modal-popup>
