/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/cbfw-header/cbfw-header.ngfactory";
import * as i3 from "cbfw-header";
import * as i4 from "../../node_modules/cbfw-footer/cbfw-footer.ngfactory";
import * as i5 from "cbfw-footer";
import * as i6 from "@angular/common";
import * as i7 from "@angular/router";
import * as i8 from "../../node_modules/ng4-loading-spinner/ng4-loading-spinner.ngfactory";
import * as i9 from "ng4-loading-spinner";
import * as i10 from "./app.component";
import * as i11 from "./shared/services/app-settings-service";
import * as i12 from "./shared/services/app-env-config";
import * as i13 from "./shared/services/contact-us.service";
import * as i14 from "./shared/services/config.service";
import * as i15 from "./shared/services/local-storage.service";
import * as i16 from "./shared/services/app-device.service";
import * as i17 from "@angular/platform-browser";
import * as i18 from "./shared/services/authenticate.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, [[2, 4]], 0, i3.HeaderComponent, [], { headerConfig: [0, "headerConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerConfig; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "notice"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notice.DisplayValue; _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-footer", [], null, [[null, "contactUsSubmitEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("contactUsSubmitEvent" === en)) {
        var pd_0 = (_co.contactUsSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CbfwFooterComponent_0, i4.RenderType_CbfwFooterComponent)), i1.ɵdid(1, 114688, [[1, 4]], 0, i5.CbfwFooterComponent, [], { footerConfig: [0, "footerConfig"] }, { contactUsSubmitEvent: "contactUsSubmitEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.footerConfig; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { cbfwFooterComponent: 0 }), i1.ɵqud(671088640, 2, { headerComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ng4-loading-spinner", [], null, null, null, i8.View_Ng4LoadingSpinnerComponent_0, i8.RenderType_Ng4LoadingSpinnerComponent)), i1.ɵdid(11, 180224, null, 0, i9.Ng4LoadingSpinnerComponent, [i9.Ng4LoadingSpinnerService], { timeout: [0, "timeout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerConfig; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.notice.IsVisible; _ck(_v, 5, 0, currVal_1); _ck(_v, 7, 0); var currVal_2 = _co.footerConfig; _ck(_v, 9, 0, currVal_2); var currVal_3 = 200000; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i10.AppComponent, [i11.AppSettingsService, i9.Ng4LoadingSpinnerService, i12.EnvConfig, i13.ContactUsService, i14.ConfigService, i1.ChangeDetectorRef, i7.ActivatedRoute, i15.LocalStorageService, i16.AppDeviceService, i17.Title, i18.AuthenticateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
