import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Customer } from '../models/customer';
import { EnvConfig } from '../services/app-env-config';

@Injectable()
export class RegistrationInfoService {

  private handleError: HandleError;
  private apiUrlEnrollment;


  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private envConfig: EnvConfig) {

    // pass this class name here;
    this.handleError = httpErrorHandler.createHandleError('CustomerInfoService');

    this.apiUrlEnrollment = this.envConfig.get('apiUrlEnrollment');
  }


  getRegistrationInfo(regcode, corporateEntityId): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('registrationCode', regcode).set('corporateEntityId', corporateEntityId) };

    return this.http.get<any>(this.apiUrlEnrollment + '/RegistrationCodes/GetRegistrationInfo', httpOptions)
      .pipe(
        catchError(this.handleError('getRegistrationInfo', []))
      );
  }

}
