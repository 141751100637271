var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConfigService = /** @class */ (function () {
    function ConfigService(http) {
        this.http = http;
        this.configUrl = './assets/app.config.json';
    }
    Object.defineProperty(ConfigService.prototype, "config", {
        get: function () {
            return this.appConfig;
        },
        enumerable: true,
        configurable: true
    });
    /// this method will be called in app module since this service sould start first
    ConfigService.prototype.loadConfig = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.get(_this.configUrl).toPromise()
                .then(function (data) {
                _this.appConfig = __assign({}, data);
                resolve(true);
            }, function (error) {
                /* tslint:disable */ console.log(error); /* tslint:enable */
                reject();
            });
        });
        return promise;
    };
    /// get method
    /**
     * privat get method to config service to avoid cyclic dependancies
      */
    ConfigService.prototype.get = function (url, params) {
        var _this = this;
        return this.http.get(url)
            .pipe(retry(3), catchError(function (err) {
            return _this.handleError(err);
        }));
    };
    /// error handling method
    ConfigService.prototype.handleError = function (error) {
        return throwError(error);
    };
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.inject(i1.HttpClient)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
