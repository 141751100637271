import { Component, OnInit, ViewChild, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from '../../../shared/services/app-settings-service';
import { AppValidationSettingsService } from '../../../shared/services/app-validation-settings-service';
import { ModalType, CbfwModalPopupComponent } from 'cbfw-modal-popup';
import { CbfwRegistrationinfoStepComponent } from 'cbfw-registrationinfo-step';

import { AppSettings } from '../../../shared/models/app-settings';
import { StateService } from '../../../shared/services/state.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { State } from '../../../shared/models/state';
import { EnrollmentData } from '../../../shared/models/enrollment/enrollment-data';
import { Registration } from '../../../shared/models/registration';
import { Customer } from '../../../shared/models/customer';
import { RegistrationInfoService } from '../../../shared/services/registration-info.service';
import { CustomerInfoService } from '../../../shared/services/customer-info.service';
import { DeviceInfoService } from '../../../shared/services/device-info.service';
import { EnrollmentService } from '../../../shared/services/enrollment.service';
import { EnvConfig } from '../../../shared/services/app-env-config';
import { CbfwCustomerinfoStepComponent } from 'cbfw-customerinfo-step';
import { CbfwDeviceinfoStepComponent } from 'cbfw-deviceinfo-step';
import { CbfwSummaryinfoStepComponent } from 'cbfw-summaryinfo-step';
import { CbfwConfirmDialogService } from 'cbfw-confirm-dialog';
import { CbfwCloseWarningModalPopupComponent } from 'cbfw-close-warning-modal-popup';
import { AddressSuggestionService } from '../../../shared/services/address-suggestion.service';
import { Address } from 'cbfw-customerinfo-step';
import { Constants } from '../../../shared/utilities/constants';
import { HtmlConstants } from '../../../shared/utilities/html-constants';

declare let $: any;

@Component({
  selector: 'app-enrollment-wizard',
  templateUrl: './enrollment-wizard.component.html',
  styleUrls: ['./enrollment-wizard.component.css']
})
export class EnrollmentWizardComponent implements OnInit, AfterViewInit {

  /// stepper defult settings
  isLinear = false;
  isStepEditable = true;

  /// enrollemnt wiz form groups
  registrationFormGroup: FormGroup;
  customerFormGroup: FormGroup;
  deviceInfoFormGroup: FormGroup;
  summaryFormGroup: FormGroup;
  confirmationFormGroup: FormGroup;

  /// enrollemnt wiz inputs
  inputDataRegistration: any = {};
  inputDataCustomer: any = {};
  inputDataDevice: any = {};
  inputDataSummary: any = {};

  // Hold the data through the enrollmennt process
  EnrollmentData: any = {};
  corporateEntityId;
  storeType;
  policyTypeId;

  dataTarget;
  dataModel;

  // Compnent variables to interact with child components
  @ViewChild(CbfwRegistrationinfoStepComponent) registrationComponent: CbfwRegistrationinfoStepComponent;
  @ViewChild(CbfwCustomerinfoStepComponent) customerComponent: CbfwCustomerinfoStepComponent;
  @ViewChild(CbfwDeviceinfoStepComponent) deviceInfoComponent: CbfwDeviceinfoStepComponent;
  @ViewChild(CbfwSummaryinfoStepComponent) summaryComponent: CbfwSummaryinfoStepComponent;
  @ViewChild(CbfwCloseWarningModalPopupComponent)
  cbfwCloseWarningModalPopupComponent: CbfwCloseWarningModalPopupComponent;

  public result: any;

  /// data sources
  states: any = {};
  manufacturers: any = {};
  models: any = {};
  carriers: any = {};
  isOtherEnable: boolean;

  constructor(private _formBuilder: FormBuilder,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private appValidationSettingsService: AppValidationSettingsService,
    private spinnerService: Ng4LoadingSpinnerService,
    private stateService: StateService,
    private registrationInfoService: RegistrationInfoService,
    private dialogsService: CbfwConfirmDialogService,
    private customerInfoService: CustomerInfoService,
    private deviceInfoService: DeviceInfoService,
    private enrollmentService: EnrollmentService,
    private envConfig: EnvConfig,
    private addressSuggestionService: AddressSuggestionService
  ) {

    /// get values form env config file
    this.corporateEntityId = this.envConfig.get('corporateEntityId');
    this.storeType = this.envConfig.get('storeType');
    this.policyTypeId = this.envConfig.get('policyTypeId');
    this.inputDataDevice.notListedModelName = this.envConfig.get('notListedModelName');
    this.isOtherEnable = this.envConfig.get('isOtherEnable');
  }

  ngOnInit() {
    this.registrationFormGroup = this._formBuilder.group({
    });
    this.customerFormGroup = this._formBuilder.group({
    });
    this.deviceInfoFormGroup = this._formBuilder.group({
    });
    this.summaryFormGroup = this._formBuilder.group({
    });
    this.confirmationFormGroup = this._formBuilder.group({
    });

    this.EnrollmentData.enrollmentSucsess = false;
    this.EnrollmentData.corporateEntityId = this.corporateEntityId;

    // Add Category Id
    (sessionStorage.getItem(Constants.sessionProductCategoryVariableName)) ?
      this.EnrollmentData.ProductCategoryIdOfMainProduct  = parseInt(sessionStorage.getItem
                                      (Constants.sessionProductCategoryVariableName), 10)
      : this.EnrollmentData.ProductCategoryIdOfMainProduct  = null;


    this.isLinear = true;
    this.getStates();

    this.inputDataSummary = this.EnrollmentData;

    this.appValidationSettingsService.getSettings()
      .subscribe(settings => {
        this.registrationComponent.validationConfig = settings.enrollment.registration;

      });
    // this.registrationComponent.modalWithCloseButtonMessage.DisplayValue =
    // HtmlConstants.registrationInfoModalMessage;

  }

  ngAfterViewInit() {
    this.customerComponent.note.DisplayValue = HtmlConstants.customerComponentNote;
    this.registrationComponent.errorPleaseContact.DisplayValue =
      Constants.regComponentPleaseContactMessage;
    this.deviceInfoComponent.modalPurchasedDateMessage.DisplayValue = HtmlConstants.modalDevicePurchaseDateMessage;
    setTimeout(() => {
      this.registrationComponent.modalWithCloseButtonMessage.DisplayValue = HtmlConstants.registrationInfoModalMessage;
    }, 2);

    if ($('h4#myModalLabel').html() === 'Registration Number') {
      $('h4#myModalLabel').parents('#registrationinfo-step-modal').addClass('small-modal');
    } else {
      $('h4#myModalLabel').parents('#registrationinfo-step-modal').removeClass('small-modal');
    }
  }

  // following form event handlers are use to bing stepper child components forms to parent component form
  registrationFormEventHandler(event: FormGroup) {
    this.registrationFormGroup = event;
  }

  /// customer form event handler
  customerFormEventHandler(event: FormGroup) {
    this.customerFormGroup = event;
  }

  /// device info form event handler
  deviceInfoFormEventHandler(event: FormGroup) {
    this.deviceInfoFormGroup = event;
  }

  /// summary form event handler
  summaryFormEventHandler(event: FormGroup) {
    this.summaryFormGroup = event;
  }

  /// confirmation form event handler
  confirmationFormEventHandler(event: FormGroup) {
    this.confirmationFormGroup = event;
  }

  /// validate registration
  validateRegistration(event: any) {
    this.EnrollmentData.RegistrationData = event;
    const regCode = this.EnrollmentData.RegistrationData.RegistrationCode;
    this.validateRegistartionCode(regCode);
  }

  /// load suggested addresses
  loadSuggestedAddresses(event) {
    if (event.target.value) {
      this.customerComponent.addressSuggestionSpinner = true;
      this.addressSuggestionService.getAddressSuggestion(event.target.value)
        .subscribe(res => {
          this.customerComponent.suggestedAddresses = [];
          if (res.status === 'OK' && res.predictions) {
            for (const address of res.predictions) {
              if (address.description.endsWith('USA')) {
                const currentAddress: Address = {
                  FormattedAddress: address.description
                };
                this.customerComponent.suggestedAddresses.push(currentAddress);
              }
            }
          } else {
            const currentAddress: Address = {
              FormattedAddress: this.customerComponent.addressSuggestionErrorMessage
            };
            this.customerComponent.suggestedAddresses.push(currentAddress);
          }
          this.customerComponent.addressSuggestionSpinner = false;
        },
          error => {
            this.customerComponent.addressSuggestionSpinner = false;
          }
        );
    } else {
      this.customerComponent.suggestedAddresses = [];
    }
  }

  /// find relevent contoller value from addressComponents array
  findContollerValue(addressComponents, type): string {
    let selectedValue = '';
    for (const currentAddressComponent of addressComponents) {
      if (currentAddressComponent.types.indexOf(type) > -1) {
        if (type === 'locality') {
          selectedValue = currentAddressComponent.long_name;
        } else {
          selectedValue = currentAddressComponent.short_name;
        }
        break;
      }
    }
    return selectedValue;
  }

  /// bind selected address
  async bindSelectedAddress() {
    for (const currentAddress of this.customerComponent.suggestedAddresses) {
      if (currentAddress.FormattedAddress === this.customerComponent.addressCtrl.value) {
        this.customerComponent.addressSuggestionSpinner = true;
        await this.addressSuggestionService.loadAddressInformation(currentAddress.FormattedAddress)
          .subscribe(res => {
            if (res.status && res.status === 'OK' && res.results && res.results.length === 1) {
              for (const address of res.results) {
                const addressInfo: Address = {
                  AddressLine1: this.findContollerValue(address.address_components, 'street_number') + ' ' +
                    this.findContollerValue(address.address_components, 'route'),
                  AddressLine2: '',
                  City: this.findContollerValue(address.address_components, 'locality'),
                  State: this.findContollerValue(address.address_components, 'administrative_area_level_1'),
                  ZipCode: Number(this.findContollerValue(address.address_components, 'postal_code'))
                };

                this.customerComponent.address1Ctrl.setValue(addressInfo.AddressLine1);
                // this.customerComponent.address2Ctrl.setValue(addressInfo.AddressLine2);
                this.customerComponent.cityCtrl.setValue(addressInfo.City);
                this.customerComponent.stateCtrl.setValue(this.getStateData(addressInfo.State));
                this.customerComponent.zipCodeCtrl.setValue(addressInfo.ZipCode);
              }
            }
            this.customerComponent.addressSuggestionSpinner = false;
          },
            error => {
              this.customerComponent.addressSuggestionSpinner = false;
            }
          );
        break;
      }
    }
  }

  getStateData(stateAttibute) {
    const stateDate = this.inputDataRegistration.states.find(x => x.stateAbbr === stateAttibute);
    return stateDate;
  }

  /// get states from stateService
  getStates(): void {
    this.spinnerService.show();
    this.stateService.getStates()
      .subscribe(res => {
        this.states = res;
        this.inputDataRegistration.states = res;
        this.inputDataCustomer.states = res;
        this.spinnerService.hide();
        this.customerComponent.filteredStates = this.inputDataCustomer.states;
        this.deviceInfoComponent.filteredStates = this.states;
      });
  }

  /// filter states
  filterStates(name: any) {
    if (typeof name === 'object') {
      return this.states.filter(state =>
        state.stateName.toLowerCase().indexOf(name.stateName.toLowerCase()) === 0);
    } else {
      return this.states.filter(state =>
        state.stateName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }
  }

  /// validate registartion code
  validateRegistartionCode(regCode: any) {
    this.spinnerService.show();
    this.registrationInfoService.getRegistrationInfo(regCode, this.corporateEntityId)
      .subscribe(res => {
        if (res.success) {
          this.EnrollmentData.AccessoryDeviceData = res;
          this.inputDataDevice.AccessoryDeviceData = res;

          // GS-349
          // set validation for store with registration code details
          this.deviceInfoComponent.setStoreSelectionOption();

          this.deviceInfoComponent.deviceInfoFormGroup.patchValue({
            accessoryCtrl: res.productName
          });

          this.registrationComponent.regCodevalidationError = false;
          this.registrationComponent.goForward();

        } else if (res.isRegistered) {
          this.dialogsService.confirm('Enrollment',
            Constants.validateRegistrateCodePleaseContactMsg);
        } else {
          this.dialogsService.confirm('Enrollment',
            Constants.validateRegstrationCodeErrorMsg);
          this.registrationComponent.regCodevalidationError = true;
        }
        this.spinnerService.hide();
      });
  }

  /// validate email
  validateEmail(event) {
    this.spinnerService.show();
    this.EnrollmentData.CustomerData = event;
    const email = this.EnrollmentData.CustomerData.Email;

    this.customerInfoService.validateEmailEmailReturnPath(email)
      .subscribe(res => {
        if (res && !res.IsValid) {

          this.spinnerService.hide();
          //Set return path email validation error
          this.customerComponent.setInvalidEmail();

        } else {

          this.customerInfoService.validateEmail(this.corporateEntityId, email)
            .subscribe(exsistingCust => {
              const exsistingStateObj = this.getExsistingCustState(exsistingCust.customerStateAbbr);
              if (exsistingCust.isEmailExist) {
                this.EnrollmentData.ExsistingCustomerState = exsistingStateObj;
              }
              this.customerComponent.setExistingtingData(exsistingCust, exsistingStateObj);
              this.spinnerService.hide();
            });
        }
      });

  }

  /// set customer data
  setCustomerData(event) {
    this.EnrollmentData.CustomerData = event;

    if (this.deviceInfoComponent && !this.deviceInfoComponent.filteredManufactures) {
      this.getManufacturers(false);
    }
  }

  /// set device data
  setDeviceData(event) {
    this.validateExistESN(event.Esn, event);
  }

  /// validate exist ESN
  validateExistESN(Esn, event) {
    this.spinnerService.show();
    this.deviceInfoService.getValidateEsn(this.corporateEntityId, Esn)
      .subscribe(res => {
        if (res === true) {
          this.spinnerService.hide();
          this.dialogsService.confirm('Enrollment', Constants.validateEsnConfirmationMsg);
        } else {
          this.EnrollmentData.DeviceData = event;
          const modelId = event.Model.id;
          const cellularSrvFlag = event.cellularSrvFlag;
          const carrierTypeId = event.Carrier ? event.Carrier.id : 0;
          this.getProductDetails(modelId, carrierTypeId, cellularSrvFlag);
        }
      });
  }

  /// get manufacturers from deviceInfoService
  getManufacturers(cellularSrvFlag) {
    this.spinnerService.show();
    let productCategoryId: number = null;
    if (Constants.showCategoryListDropDownForIgr) {
      productCategoryId = parseInt(sessionStorage.getItem(Constants.sessionProductCategoryVariableName), 10);
    }
    this.deviceInfoService.getManufacturers(this.corporateEntityId, this.storeType, cellularSrvFlag, productCategoryId)
      .subscribe(res => {

        if (!this.isOtherEnable) {
          for (let index = 0; index < res.length; index++) {
            if (res[index].manufacturerName && res[index].manufacturerName.toLowerCase() === 'other') {
              res.splice(index, 1);
            }
          }
        }

        this.manufacturers = res;
        this.spinnerService.hide();
        // this.deviceInfoComponent.filteredManufactures = this.deviceInfoComponent.manufacturerCtrl.valueChanges
        //  .pipe(
        //    startWith(''),
        //    map(manuFact => manuFact ? this.filterManufactures(manuFact) : this.manufacturers.slice())
        //  );
        this.deviceInfoComponent.filteredManufactures = this.manufacturers;
      });
  }

  /// filter manufactures
  filterManufactures(searchManuFact: any) {
    if (typeof searchManuFact === 'object') {
      return this.manufacturers.filter(manuFact =>
        manuFact.manufacturerName.toLowerCase().indexOf(searchManuFact.manufacturerName.toLowerCase()) === 0);
    } else {
      return this.manufacturers.filter(manuFact =>
        manuFact.manufacturerName.toLowerCase().indexOf(searchManuFact.toLowerCase()) === 0);
    }
  }

  /// get models
  getGetModels(event) {
    this.spinnerService.show();
    this.deviceInfoService.getModels(event.manufacturerId,
      this.corporateEntityId, this.storeType, event.cellularSrvFlag)
      .subscribe(res => {

        if (!this.isOtherEnable) {
          for (let index = 0; index < res.length; index++) {
            if (res[index].modelName && res[index].modelName.toLowerCase() === 'other') {
              res.splice(index, 1);
            }
          }
        }

        // this.inputDataDevice.models = res;
        this.spinnerService.hide();
        this.models = res;
        // this.deviceInfoComponent.filteredModels = this.deviceInfoComponent.modelCtrl.valueChanges
        //  .pipe(
        //    startWith(''),
        //    map(model => model ? this.filterModels(model) : this.models.slice())
        //  );
        this.deviceInfoComponent.filteredModels = this.models;
      });
  }

  async getDefultState() {
    this.spinnerService.show();
    const storesData: any = {};
    storesData.stateId = this.EnrollmentData.CustomerData.State.id;
    storesData.stateName = this.EnrollmentData.CustomerData.State.stateName;

    await this.deviceInfoService.getStores(storesData, this.corporateEntityId)
      .subscribe(res => {
        this.spinnerService.hide();
        this.deviceInfoComponent.defultStore = res;
        this.deviceInfoComponent.setDeviceDataEmit();
      });
  }


  /// get device stores by state
  // getDeviceStoresByState(event) {
  //   this.spinnerService.show();
  //   this.deviceInfoService.getStores(event, this.corporateEntityId)
  //     .subscribe(res => {
  //       this.spinnerService.hide();
  //       this.models = res;
  //       this.deviceInfoComponent.filteredStores = this.models;
  //     });
  // }

  /// filter Models
  filterModels(searchModel: any) {
    if (typeof searchModel === 'object') {
      return this.models.filter(model =>
        model.modelName.toLowerCase().indexOf(searchModel.modelName.toLowerCase()) === 0);
    } else {
      return this.models.filter(model =>
        model.modelName.toLowerCase().indexOf(searchModel.toLowerCase()) >= 0);
    }
  }

  /// get carriers
  getCarriers(modelId) {
    this.spinnerService.show();
    this.deviceInfoService.getCarriers(this.corporateEntityId, this.storeType, modelId)
      .subscribe(res => {
        if (res.length > 0) {
          // this.inputDataDevice.carriers = res;
          this.carriers = res;
          // this.deviceInfoComponent.filteredCarriers = this.deviceInfoComponent.carrierCtrl.valueChanges
          //  .pipe(
          //    startWith(''),
          //    map(carrier => carrier ? this.filterCarriers(carrier) : this.carriers.slice())
          //  );
          this.deviceInfoComponent.filteredCarriers = this.carriers;
        } else {
          this.dialogsService.confirm('Enrollment', Constants.noCarrierMsg);
        }
        this.spinnerService.hide();
      });
  }

  /// filter carriers
  filterCarriers(searchCarrier: any) {
    if (typeof searchCarrier === 'object') {
      return this.carriers.filter(carrier =>
        carrier.carrierName.toLowerCase().indexOf(searchCarrier.carrierName.toLowerCase()) === 0);
    } else {
      return this.carriers.filter(carrier =>
        carrier.carrierName.toLowerCase().indexOf(searchCarrier.toLowerCase()) === 0);
    }
  }

  /// upload ESN screenshot
  uploadEsnScreenshot(esnFile: File) {

    if (this.validateFileType(esnFile.name.toLocaleLowerCase())) {
      if (!this.validateFileSize(esnFile)) {
        this.dialogsService.confirm('Enrollment', Constants.maximumFileUploadSizeExceededErrorMessage);
        this.deviceInfoComponent.esnUploadProgess = false;
      } else {
        this.deviceInfoService.uploadEsnScreenshot(esnFile)
          .subscribe(res => {
            this.EnrollmentData.esnFileId = res.result;
            this.deviceInfoComponent.setEsnFileName(esnFile.name);
          });
      }
    } else {
      this.dialogsService.confirm('Enrollment', Constants.invalidFileType);
      this.deviceInfoComponent.esnUploadProgess = false;
    }
  }

  /// upload invoice file
  uploadInvoiceFile(invoiceFile: File) {
    if (this.validateFileType(invoiceFile.name.toLocaleLowerCase())) {
      if (!this.validateFileSize(invoiceFile)) {
        this.dialogsService.confirm('Enrollment', Constants.maximumFileUploadSizeExceededErrorMessage);
        this.deviceInfoComponent.invoiceUploadProgess = false;
      } else {
        this.deviceInfoService.uploadInvoiceFile(invoiceFile)
          .subscribe(res => {
            this.EnrollmentData.invoiceFileId = res.result;
            this.deviceInfoComponent.setInvoiceFileName(invoiceFile.name);
          });
      }
    } else {
      this.dialogsService.confirm('Enrollment', Constants.invalidFileType);
      this.deviceInfoComponent.invoiceUploadProgess = false;
    }
  }

  /// get product details
  getProductDetails(modelId: any, carrierTypeId: any, cellularSrvFlag) {
    this.spinnerService.show();
    this.deviceInfoService.getProductDetails(this.corporateEntityId,
      this.storeType, modelId, carrierTypeId, cellularSrvFlag)
      .subscribe(res => {
        if (res) {
          if (Object.keys(res).length > 0) {
            this.EnrollmentData.ProductData = res;
            this.getPlanDetails(res.productCategoryId);
          } else {
            this.spinnerService.hide();
            this.dialogsService.confirm('Enrollment', Constants.noProductMsg
            );
          }
        } else {
          this.spinnerService.hide();
          this.dialogsService.confirm('Enrollment', Constants.noProductMsg);
        }
      });
  }

  /// get plan details
  getPlanDetails(productCategoryId) {
    const stateAbbr = this.EnrollmentData.CustomerData.State.stateAbbr;
    const subcategoryId = this.EnrollmentData.AccessoryDeviceData.productCategoryId;
    this.deviceInfoService.getPlanDetails(this.corporateEntityId,
      stateAbbr, productCategoryId, subcategoryId, this.policyTypeId)
      .subscribe(res => {
        if (res.length > 0) {
          this.EnrollmentData.PlanData = res;
          this.deviceInfoComponent.goForward();
        } else {
          this.dialogsService.confirm('Enrollment', Constants.noPlanMsg);
        }
        this.spinnerService.hide();
      });
  }

  /// get exsisting cust state
  getExsistingCustState(stateAbbr: string) {
    return this.states.find(
      state => state.stateAbbr === stateAbbr
    );
  }

  /// enroll
  enroll(event) {
    this.spinnerService.show();
    this.isStepEditable = false;
    this.enrollmentService.enroll(event)
      .subscribe(res => {
        let policyNumber;
        let isEnrollSuccess: boolean;
        let porductName;

        if (res) {
          if (res.length > 0) {
            // this.confirmationComponent.policyNumber = res[0].PolicyNumber;
            // this.confirmationComponent.isEnrollSuccess = true;
            // this.confirmationComponent.porductName = this.EnrollmentData.AccessoryDeviceData.productName;

            policyNumber = res[0].PolicyNumber;
            isEnrollSuccess = true;
            porductName = this.EnrollmentData.AccessoryDeviceData.productName;
          } else {
            // this.confirmationComponent.isEnrollSuccess = false;
            isEnrollSuccess = false;
          }
        } else {
          // this.confirmationComponent.isEnrollSuccess = false;
          isEnrollSuccess = false;
        }
        this.router.navigate(['/enrollment/confirmation'],
          {
            queryParams: {
              'policyNumber': policyNumber,
              'isEnrollSuccess': isEnrollSuccess,
              'porductName': porductName
            }
          });

        this.spinnerService.hide();
      });
  }

  /// cancel enrollment
  cancelEnrollment() {
    this.router.navigate(['./home']);
  }

  /// open dialog
  public openDialog() {
    this.dialogsService
      .confirm('Confirm Dialog', Constants.areYouSureConfirmMessage)
      .subscribe(res => this.result = res);
  }

  /// reg step select
  regStepSelect() {
    /* tslint:disable */
    console.log('res step selected');
    /* tslint:enable */
  }

  /// validate file size
  validateFileSize(file: File): boolean {
    if (file.size > 6291456) {
      return false;
    } else {
      return true;
    }
  }

  /// validate file type
  validateFileType(name: string): boolean {
    const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'tiff'
      || ext === 'tif' || ext === 'pdf' || ext === 'bmp') {
      return true;
    } else {
      return false;
    }
  }

  /// wiz close warning message pop-up
  wizCloseWarningMessagePopUp() {
    /// close warning pop-up messgage values set
    this.cbfwCloseWarningModalPopupComponent.title.DisplayValue = 'Enrollment';
    // this.cbfwCloseWarningModalPopupComponent.imageWarning.DisplayValue = 'sdfsd';
    this.cbfwCloseWarningModalPopupComponent.warningMessage.DisplayValue = Constants.enrollmentCancelConfirmationMsg;

    this.cbfwCloseWarningModalPopupComponent.show();
  }
}
