import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PolicyLookupComponent } from './claim/components/policy-lookup/policy-lookup.component';
import { PolicyDetailsComponent } from './claim/components/policy-details/policy-details.component';
import { ClaimWizardComponent } from './claim/components/claim-wizard/claim-wizard.component';
import { HomeComponent } from './shared/component/home/home.component';

import { HeaderModule } from 'cbfw-header';
import { FooterModule } from 'cbfw-footer';
import { HeroBannerModule } from 'cbfw-hero-banner';
import { PolicyLookupModule } from 'cbfw-policy-lookup';
import { PolicyDetailsModule } from 'cbfw-policy-details';
import { ClaimSummaryStepModule } from 'cbfw-claim-summary-step';
import { CheckDeliveryStepModule } from 'cbfw-check-delivery-step';
import { InvoiceStepModule } from 'cbfw-invoice-step';
import { IncidentDetailsStepModule } from 'cbfw-incident-details-step';
import { ConfirmDialogModule } from 'cbfw-confirm-dialog';
import { ClaimConfirmationModule } from 'cbfw-claim-confirmation';
import { RegistrationinfoStepModule } from 'cbfw-registrationinfo-step';
import { CustomerinfoStepModule } from 'cbfw-customerinfo-step';
import { DeviceinfoStepModule } from 'cbfw-deviceinfo-step';
import { SummaryinfoStepModule } from 'cbfw-summaryinfo-step';
import { ConfirmationinfoStepModule } from 'cbfw-confirmationinfo-step';
import { EnrollmentDeviceTypeModule } from 'cbfw-enrollment-device-type';
import { EnrollmentMobileDeviceModule } from 'cbfw-enrollment-mobile-device';
import { ContactUsModule } from 'cbfw-contact-us';

import { ModalPopupModule } from 'cbfw-modal-popup';
import {CollapsableModalPopupModule} from 'cbfw-collapsable-modal-popup';

import { CbfwClaimStepperComponent } from 'cbfw-claim-stepper';


import { AmazingTimePickerModule } from 'amazing-time-picker';
import { EnvConfig } from './shared/services/app-env-config';

import { AppSettingsService } from './shared/services/app-settings-service';
import { PolicyLookupService } from './claim/services/policy-lookup.service';
import { ClaimDataManagementService } from './claim/services/claim-data-management.service';
import { HttpErrorHandler } from './shared/services/http-error-handler.service';
import { MessageService } from './shared/services/message.service';
import { TokenInterceptor } from './shared/services/token-interceptor.service';
import {
  MAT_DIALOG_DEFAULT_OPTIONS, MatStepperModule, MatFormFieldModule, MatInputModule,
  MatAutocompleteModule, MatCheckboxModule, MatRadioModule, MatSelectModule, MatProgressBarModule,
  MatIconModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule, MatProgressSpinnerModule
} from '@angular/material';
import { AppValidationSettingsService } from './shared/services/app-validation-settings-service';
import { StateService } from './shared/services/state.service';
import { CustomerInfoService } from './shared/services/customer-info.service';
import { DeviceInfoService } from './shared/services/device-info.service';
import { EnrollmentService } from './shared/services/enrollment.service';
import { RegistrationInfoService } from './shared/services/registration-info.service';
import { ContactUsService } from './shared/services/contact-us.service';
import { InvoiceService } from './claim/services/invoice.service';
import { ClaimService } from './claim/services/claim.service';
import { CheckDeliveryService } from './claim/services/check-delivery.service';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { EscapeHtmlPipe } from './shared/pipes/keep-html.pipe';
import { ClaimConfirmationComponent } from './claim/components/claim-confirmation/claim-confirmation.component';
import { EnrollmentWizardComponent } from './enrollment/components/enrollment-wizard/enrollment-wizard.component';
import { EnrollConfirmationComponent } from './enrollment/components/enroll-confirmation/enroll-confirmation.component';
import {
  EnrollmentDeviceTypeComponent
} from './enrollment/components/enrollment-device-type/enrollment-device-type.component';
import {
  EnrollmentMobileDeviceComponent
} from './enrollment/components/enrollment-mobile-device/enrollment-mobile-device.component';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { CloseWarningModalPopupModule } from 'cbfw-close-warning-modal-popup';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AddressSuggestionService } from './shared/services/address-suggestion.service';
import { FaqComponent } from './shared/component/faq/faq.component';
import { ConfigService } from './shared/services/config.service';
import { TermsAndConditionsComponent } from './shared/component/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './shared/component/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './shared/component/contact-us/contact-us.component';
import { AuthenticateService } from './shared/services/authenticate.service';
import { environment } from '../environments/environment';
import { EnrollmentGuard } from './shared/router-guards/enrollment-guard';

const appInitializerFn = (appConfig: ConfigService) => {
  return () => {
    return appConfig.loadConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    EscapeHtmlPipe,
    PolicyLookupComponent,
    PolicyDetailsComponent,
    ClaimWizardComponent,
    HomeComponent,
    ClaimConfirmationComponent,
    EnrollmentWizardComponent,
    EnrollConfirmationComponent,
    EnrollmentDeviceTypeComponent,
    EnrollmentMobileDeviceComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    PolicyLookupModule,
    PolicyDetailsModule,
    InvoiceStepModule,
    IncidentDetailsStepModule,
    CheckDeliveryStepModule,
    ClaimSummaryStepModule,
    ConfirmDialogModule,
    ClaimConfirmationModule,
    ModalPopupModule,
    CollapsableModalPopupModule,
    CloseWarningModalPopupModule,
    FooterModule,
    HeroBannerModule,
    RegistrationinfoStepModule,
    CustomerinfoStepModule,
    DeviceinfoStepModule,
    SummaryinfoStepModule,
    ConfirmationinfoStepModule,
    EnrollmentDeviceTypeModule,
    EnrollmentMobileDeviceModule,
    ContactUsModule,
    BrowserModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    // NoopAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressBarModule,
    HeaderModule,
    HeroBannerModule,
    FooterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    // RegistrationInfoModule,
    // CustomerInfoModule,
    // DeviceInfoModule,
    // SummaryInfoModule,
    // ConfirmationInfoModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // BrowserAnimationsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    Ng4LoadingSpinnerModule.forRoot(),
    // DialogsModule,
    AmazingTimePickerModule,
    TextMaskModule,
    NgxMaskModule.forRoot(),
    NgxCaptchaModule.forRoot({
      reCaptcha2SiteKey: environment.reCaptcha2SiteKey,
      invisibleCaptchaSiteKey: 'yyy'
    })
  ],
  providers: [
    EnrollmentGuard,
    AppSettingsService,
    PolicyLookupService,
    ClaimDataManagementService,
    HttpErrorHandler,
    MessageService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    AppSettingsService,
    AppValidationSettingsService,
    StateService,
    CustomerInfoService,
    DeviceInfoService,
    EnrollmentService,
    RegistrationInfoService,
    ContactUsService,
    PolicyLookupService,
    ClaimDataManagementService,
    InvoiceService,
    ClaimService,
    CheckDeliveryService,
    AddressSuggestionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthenticateService,
    { provide: EnvConfig, useFactory: () => EnvConfig.getInstance('../assets/env-config.json') },
    ConfigService,
    { provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [ConfigService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
