import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PolicyLookupComponent } from './claim/components/policy-lookup/policy-lookup.component';
import { PolicyDetailsComponent } from './claim/components/policy-details/policy-details.component';
import { ClaimWizardComponent } from './claim/components/claim-wizard/claim-wizard.component';
import { HomeComponent } from './shared/component/home/home.component';
import { ClaimConfirmationComponent } from './claim/components/claim-confirmation/claim-confirmation.component';
import { EnrollmentWizardComponent } from './enrollment/components/enrollment-wizard/enrollment-wizard.component';
import { EnrollConfirmationComponent } from './enrollment/components/enroll-confirmation/enroll-confirmation.component';
import {
  EnrollmentDeviceTypeComponent
} from './enrollment/components/enrollment-device-type/enrollment-device-type.component';
import {
  EnrollmentMobileDeviceComponent
} from './enrollment/components/enrollment-mobile-device/enrollment-mobile-device.component';
import { FaqComponent } from './shared/component/faq/faq.component';
import { TermsAndConditionsComponent } from './shared/component/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './shared/component/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './shared/component/contact-us/contact-us.component';
import { EnrollmentGuard } from './shared/router-guards/enrollment-guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'termsAndConditions', component: TermsAndConditionsComponent },
  { path: 'privacyPolicy', component: PrivacyPolicyComponent },
  { path: 'contactUs', component: ContactUsComponent },
  {
    path: 'enrollment',
    children: [
      { path: 'enroll', component: EnrollmentWizardComponent },
      { path: 'confirmation', component: EnrollConfirmationComponent },
      { path: 'deviceType', component: EnrollmentDeviceTypeComponent },
      { path: 'mobileDevice', component: EnrollmentMobileDeviceComponent }
    ], canActivate: [EnrollmentGuard]
  },
  {
    path: 'claim',
    children: [
      { path: 'policyLookup', component: PolicyLookupComponent },
      { path: 'policyDetails', component: PolicyDetailsComponent },
      { path: 'claimFiling', component: ClaimWizardComponent },
      { path: 'confirmation', component: ClaimConfirmationComponent }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      useHash: true
    })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
