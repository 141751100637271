var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from '../../../shared/services/app-settings-service';
import { AppValidationSettingsService } from '../../../shared/services/app-validation-settings-service';
import { CbfwRegistrationinfoStepComponent } from 'cbfw-registrationinfo-step';
import { StateService } from '../../../shared/services/state.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { RegistrationInfoService } from '../../../shared/services/registration-info.service';
import { CustomerInfoService } from '../../../shared/services/customer-info.service';
import { DeviceInfoService } from '../../../shared/services/device-info.service';
import { EnrollmentService } from '../../../shared/services/enrollment.service';
import { EnvConfig } from '../../../shared/services/app-env-config';
import { CbfwCustomerinfoStepComponent } from 'cbfw-customerinfo-step';
import { CbfwDeviceinfoStepComponent } from 'cbfw-deviceinfo-step';
import { CbfwSummaryinfoStepComponent } from 'cbfw-summaryinfo-step';
import { CbfwConfirmDialogService } from 'cbfw-confirm-dialog';
import { CbfwCloseWarningModalPopupComponent } from 'cbfw-close-warning-modal-popup';
import { AddressSuggestionService } from '../../../shared/services/address-suggestion.service';
import { Constants } from '../../../shared/utilities/constants';
import { HtmlConstants } from '../../../shared/utilities/html-constants';
var EnrollmentWizardComponent = /** @class */ (function () {
    function EnrollmentWizardComponent(_formBuilder, router, appSettingsService, appValidationSettingsService, spinnerService, stateService, registrationInfoService, dialogsService, customerInfoService, deviceInfoService, enrollmentService, envConfig, addressSuggestionService) {
        this._formBuilder = _formBuilder;
        this.router = router;
        this.appSettingsService = appSettingsService;
        this.appValidationSettingsService = appValidationSettingsService;
        this.spinnerService = spinnerService;
        this.stateService = stateService;
        this.registrationInfoService = registrationInfoService;
        this.dialogsService = dialogsService;
        this.customerInfoService = customerInfoService;
        this.deviceInfoService = deviceInfoService;
        this.enrollmentService = enrollmentService;
        this.envConfig = envConfig;
        this.addressSuggestionService = addressSuggestionService;
        /// stepper defult settings
        this.isLinear = false;
        this.isStepEditable = true;
        /// enrollemnt wiz inputs
        this.inputDataRegistration = {};
        this.inputDataCustomer = {};
        this.inputDataDevice = {};
        this.inputDataSummary = {};
        // Hold the data through the enrollmennt process
        this.EnrollmentData = {};
        /// data sources
        this.states = {};
        this.manufacturers = {};
        this.models = {};
        this.carriers = {};
        /// get values form env config file
        this.corporateEntityId = this.envConfig.get('corporateEntityId');
        this.storeType = this.envConfig.get('storeType');
        this.policyTypeId = this.envConfig.get('policyTypeId');
        this.inputDataDevice.notListedModelName = this.envConfig.get('notListedModelName');
        this.isOtherEnable = this.envConfig.get('isOtherEnable');
    }
    EnrollmentWizardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.registrationFormGroup = this._formBuilder.group({});
        this.customerFormGroup = this._formBuilder.group({});
        this.deviceInfoFormGroup = this._formBuilder.group({});
        this.summaryFormGroup = this._formBuilder.group({});
        this.confirmationFormGroup = this._formBuilder.group({});
        this.EnrollmentData.enrollmentSucsess = false;
        this.EnrollmentData.corporateEntityId = this.corporateEntityId;
        // Add Category Id
        (sessionStorage.getItem(Constants.sessionProductCategoryVariableName)) ?
            this.EnrollmentData.ProductCategoryIdOfMainProduct = parseInt(sessionStorage.getItem(Constants.sessionProductCategoryVariableName), 10)
            : this.EnrollmentData.ProductCategoryIdOfMainProduct = null;
        this.isLinear = true;
        this.getStates();
        this.inputDataSummary = this.EnrollmentData;
        this.appValidationSettingsService.getSettings()
            .subscribe(function (settings) {
            _this.registrationComponent.validationConfig = settings.enrollment.registration;
        });
        // this.registrationComponent.modalWithCloseButtonMessage.DisplayValue =
        // HtmlConstants.registrationInfoModalMessage;
    };
    EnrollmentWizardComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.customerComponent.note.DisplayValue = HtmlConstants.customerComponentNote;
        this.registrationComponent.errorPleaseContact.DisplayValue =
            Constants.regComponentPleaseContactMessage;
        this.deviceInfoComponent.modalPurchasedDateMessage.DisplayValue = HtmlConstants.modalDevicePurchaseDateMessage;
        setTimeout(function () {
            _this.registrationComponent.modalWithCloseButtonMessage.DisplayValue = HtmlConstants.registrationInfoModalMessage;
        }, 2);
        if ($('h4#myModalLabel').html() === 'Registration Number') {
            $('h4#myModalLabel').parents('#registrationinfo-step-modal').addClass('small-modal');
        }
        else {
            $('h4#myModalLabel').parents('#registrationinfo-step-modal').removeClass('small-modal');
        }
    };
    // following form event handlers are use to bing stepper child components forms to parent component form
    EnrollmentWizardComponent.prototype.registrationFormEventHandler = function (event) {
        this.registrationFormGroup = event;
    };
    /// customer form event handler
    EnrollmentWizardComponent.prototype.customerFormEventHandler = function (event) {
        this.customerFormGroup = event;
    };
    /// device info form event handler
    EnrollmentWizardComponent.prototype.deviceInfoFormEventHandler = function (event) {
        this.deviceInfoFormGroup = event;
    };
    /// summary form event handler
    EnrollmentWizardComponent.prototype.summaryFormEventHandler = function (event) {
        this.summaryFormGroup = event;
    };
    /// confirmation form event handler
    EnrollmentWizardComponent.prototype.confirmationFormEventHandler = function (event) {
        this.confirmationFormGroup = event;
    };
    /// validate registration
    EnrollmentWizardComponent.prototype.validateRegistration = function (event) {
        this.EnrollmentData.RegistrationData = event;
        var regCode = this.EnrollmentData.RegistrationData.RegistrationCode;
        this.validateRegistartionCode(regCode);
    };
    /// load suggested addresses
    EnrollmentWizardComponent.prototype.loadSuggestedAddresses = function (event) {
        var _this = this;
        if (event.target.value) {
            this.customerComponent.addressSuggestionSpinner = true;
            this.addressSuggestionService.getAddressSuggestion(event.target.value)
                .subscribe(function (res) {
                _this.customerComponent.suggestedAddresses = [];
                if (res.status === 'OK' && res.predictions) {
                    for (var _i = 0, _a = res.predictions; _i < _a.length; _i++) {
                        var address = _a[_i];
                        if (address.description.endsWith('USA')) {
                            var currentAddress = {
                                FormattedAddress: address.description
                            };
                            _this.customerComponent.suggestedAddresses.push(currentAddress);
                        }
                    }
                }
                else {
                    var currentAddress = {
                        FormattedAddress: _this.customerComponent.addressSuggestionErrorMessage
                    };
                    _this.customerComponent.suggestedAddresses.push(currentAddress);
                }
                _this.customerComponent.addressSuggestionSpinner = false;
            }, function (error) {
                _this.customerComponent.addressSuggestionSpinner = false;
            });
        }
        else {
            this.customerComponent.suggestedAddresses = [];
        }
    };
    /// find relevent contoller value from addressComponents array
    EnrollmentWizardComponent.prototype.findContollerValue = function (addressComponents, type) {
        var selectedValue = '';
        for (var _i = 0, addressComponents_1 = addressComponents; _i < addressComponents_1.length; _i++) {
            var currentAddressComponent = addressComponents_1[_i];
            if (currentAddressComponent.types.indexOf(type) > -1) {
                if (type === 'locality') {
                    selectedValue = currentAddressComponent.long_name;
                }
                else {
                    selectedValue = currentAddressComponent.short_name;
                }
                break;
            }
        }
        return selectedValue;
    };
    /// bind selected address
    EnrollmentWizardComponent.prototype.bindSelectedAddress = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _i, _a, currentAddress;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _i = 0, _a = this.customerComponent.suggestedAddresses;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        currentAddress = _a[_i];
                        if (!(currentAddress.FormattedAddress === this.customerComponent.addressCtrl.value)) return [3 /*break*/, 3];
                        this.customerComponent.addressSuggestionSpinner = true;
                        return [4 /*yield*/, this.addressSuggestionService.loadAddressInformation(currentAddress.FormattedAddress)
                                .subscribe(function (res) {
                                if (res.status && res.status === 'OK' && res.results && res.results.length === 1) {
                                    for (var _i = 0, _a = res.results; _i < _a.length; _i++) {
                                        var address = _a[_i];
                                        var addressInfo = {
                                            AddressLine1: _this.findContollerValue(address.address_components, 'street_number') + ' ' +
                                                _this.findContollerValue(address.address_components, 'route'),
                                            AddressLine2: '',
                                            City: _this.findContollerValue(address.address_components, 'locality'),
                                            State: _this.findContollerValue(address.address_components, 'administrative_area_level_1'),
                                            ZipCode: Number(_this.findContollerValue(address.address_components, 'postal_code'))
                                        };
                                        _this.customerComponent.address1Ctrl.setValue(addressInfo.AddressLine1);
                                        // this.customerComponent.address2Ctrl.setValue(addressInfo.AddressLine2);
                                        _this.customerComponent.cityCtrl.setValue(addressInfo.City);
                                        _this.customerComponent.stateCtrl.setValue(_this.getStateData(addressInfo.State));
                                        _this.customerComponent.zipCodeCtrl.setValue(addressInfo.ZipCode);
                                    }
                                }
                                _this.customerComponent.addressSuggestionSpinner = false;
                            }, function (error) {
                                _this.customerComponent.addressSuggestionSpinner = false;
                            })];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EnrollmentWizardComponent.prototype.getStateData = function (stateAttibute) {
        var stateDate = this.inputDataRegistration.states.find(function (x) { return x.stateAbbr === stateAttibute; });
        return stateDate;
    };
    /// get states from stateService
    EnrollmentWizardComponent.prototype.getStates = function () {
        var _this = this;
        this.spinnerService.show();
        this.stateService.getStates()
            .subscribe(function (res) {
            _this.states = res;
            _this.inputDataRegistration.states = res;
            _this.inputDataCustomer.states = res;
            _this.spinnerService.hide();
            _this.customerComponent.filteredStates = _this.inputDataCustomer.states;
            _this.deviceInfoComponent.filteredStates = _this.states;
        });
    };
    /// filter states
    EnrollmentWizardComponent.prototype.filterStates = function (name) {
        if (typeof name === 'object') {
            return this.states.filter(function (state) {
                return state.stateName.toLowerCase().indexOf(name.stateName.toLowerCase()) === 0;
            });
        }
        else {
            return this.states.filter(function (state) {
                return state.stateName.toLowerCase().indexOf(name.toLowerCase()) === 0;
            });
        }
    };
    /// validate registartion code
    EnrollmentWizardComponent.prototype.validateRegistartionCode = function (regCode) {
        var _this = this;
        this.spinnerService.show();
        this.registrationInfoService.getRegistrationInfo(regCode, this.corporateEntityId)
            .subscribe(function (res) {
            if (res.success) {
                _this.EnrollmentData.AccessoryDeviceData = res;
                _this.inputDataDevice.AccessoryDeviceData = res;
                // GS-349
                // set validation for store with registration code details
                _this.deviceInfoComponent.setStoreSelectionOption();
                _this.deviceInfoComponent.deviceInfoFormGroup.patchValue({
                    accessoryCtrl: res.productName
                });
                _this.registrationComponent.regCodevalidationError = false;
                _this.registrationComponent.goForward();
            }
            else if (res.isRegistered) {
                _this.dialogsService.confirm('Enrollment', Constants.validateRegistrateCodePleaseContactMsg);
            }
            else {
                _this.dialogsService.confirm('Enrollment', Constants.validateRegstrationCodeErrorMsg);
                _this.registrationComponent.regCodevalidationError = true;
            }
            _this.spinnerService.hide();
        });
    };
    /// validate email
    EnrollmentWizardComponent.prototype.validateEmail = function (event) {
        var _this = this;
        this.spinnerService.show();
        this.EnrollmentData.CustomerData = event;
        var email = this.EnrollmentData.CustomerData.Email;
        this.customerInfoService.validateEmailEmailReturnPath(email)
            .subscribe(function (res) {
            if (res && !res.IsValid) {
                _this.spinnerService.hide();
                //Set return path email validation error
                _this.customerComponent.setInvalidEmail();
            }
            else {
                _this.customerInfoService.validateEmail(_this.corporateEntityId, email)
                    .subscribe(function (exsistingCust) {
                    var exsistingStateObj = _this.getExsistingCustState(exsistingCust.customerStateAbbr);
                    if (exsistingCust.isEmailExist) {
                        _this.EnrollmentData.ExsistingCustomerState = exsistingStateObj;
                    }
                    _this.customerComponent.setExistingtingData(exsistingCust, exsistingStateObj);
                    _this.spinnerService.hide();
                });
            }
        });
    };
    /// set customer data
    EnrollmentWizardComponent.prototype.setCustomerData = function (event) {
        this.EnrollmentData.CustomerData = event;
        if (this.deviceInfoComponent && !this.deviceInfoComponent.filteredManufactures) {
            this.getManufacturers(false);
        }
    };
    /// set device data
    EnrollmentWizardComponent.prototype.setDeviceData = function (event) {
        this.validateExistESN(event.Esn, event);
    };
    /// validate exist ESN
    EnrollmentWizardComponent.prototype.validateExistESN = function (Esn, event) {
        var _this = this;
        this.spinnerService.show();
        this.deviceInfoService.getValidateEsn(this.corporateEntityId, Esn)
            .subscribe(function (res) {
            if (res === true) {
                _this.spinnerService.hide();
                _this.dialogsService.confirm('Enrollment', Constants.validateEsnConfirmationMsg);
            }
            else {
                _this.EnrollmentData.DeviceData = event;
                var modelId = event.Model.id;
                var cellularSrvFlag = event.cellularSrvFlag;
                var carrierTypeId = event.Carrier ? event.Carrier.id : 0;
                _this.getProductDetails(modelId, carrierTypeId, cellularSrvFlag);
            }
        });
    };
    /// get manufacturers from deviceInfoService
    EnrollmentWizardComponent.prototype.getManufacturers = function (cellularSrvFlag) {
        var _this = this;
        this.spinnerService.show();
        var productCategoryId = null;
        if (Constants.showCategoryListDropDownForIgr) {
            productCategoryId = parseInt(sessionStorage.getItem(Constants.sessionProductCategoryVariableName), 10);
        }
        this.deviceInfoService.getManufacturers(this.corporateEntityId, this.storeType, cellularSrvFlag, productCategoryId)
            .subscribe(function (res) {
            if (!_this.isOtherEnable) {
                for (var index = 0; index < res.length; index++) {
                    if (res[index].manufacturerName && res[index].manufacturerName.toLowerCase() === 'other') {
                        res.splice(index, 1);
                    }
                }
            }
            _this.manufacturers = res;
            _this.spinnerService.hide();
            // this.deviceInfoComponent.filteredManufactures = this.deviceInfoComponent.manufacturerCtrl.valueChanges
            //  .pipe(
            //    startWith(''),
            //    map(manuFact => manuFact ? this.filterManufactures(manuFact) : this.manufacturers.slice())
            //  );
            _this.deviceInfoComponent.filteredManufactures = _this.manufacturers;
        });
    };
    /// filter manufactures
    EnrollmentWizardComponent.prototype.filterManufactures = function (searchManuFact) {
        if (typeof searchManuFact === 'object') {
            return this.manufacturers.filter(function (manuFact) {
                return manuFact.manufacturerName.toLowerCase().indexOf(searchManuFact.manufacturerName.toLowerCase()) === 0;
            });
        }
        else {
            return this.manufacturers.filter(function (manuFact) {
                return manuFact.manufacturerName.toLowerCase().indexOf(searchManuFact.toLowerCase()) === 0;
            });
        }
    };
    /// get models
    EnrollmentWizardComponent.prototype.getGetModels = function (event) {
        var _this = this;
        this.spinnerService.show();
        this.deviceInfoService.getModels(event.manufacturerId, this.corporateEntityId, this.storeType, event.cellularSrvFlag)
            .subscribe(function (res) {
            if (!_this.isOtherEnable) {
                for (var index = 0; index < res.length; index++) {
                    if (res[index].modelName && res[index].modelName.toLowerCase() === 'other') {
                        res.splice(index, 1);
                    }
                }
            }
            // this.inputDataDevice.models = res;
            _this.spinnerService.hide();
            _this.models = res;
            // this.deviceInfoComponent.filteredModels = this.deviceInfoComponent.modelCtrl.valueChanges
            //  .pipe(
            //    startWith(''),
            //    map(model => model ? this.filterModels(model) : this.models.slice())
            //  );
            _this.deviceInfoComponent.filteredModels = _this.models;
        });
    };
    EnrollmentWizardComponent.prototype.getDefultState = function () {
        return __awaiter(this, void 0, void 0, function () {
            var storesData;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinnerService.show();
                        storesData = {};
                        storesData.stateId = this.EnrollmentData.CustomerData.State.id;
                        storesData.stateName = this.EnrollmentData.CustomerData.State.stateName;
                        return [4 /*yield*/, this.deviceInfoService.getStores(storesData, this.corporateEntityId)
                                .subscribe(function (res) {
                                _this.spinnerService.hide();
                                _this.deviceInfoComponent.defultStore = res;
                                _this.deviceInfoComponent.setDeviceDataEmit();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /// get device stores by state
    // getDeviceStoresByState(event) {
    //   this.spinnerService.show();
    //   this.deviceInfoService.getStores(event, this.corporateEntityId)
    //     .subscribe(res => {
    //       this.spinnerService.hide();
    //       this.models = res;
    //       this.deviceInfoComponent.filteredStores = this.models;
    //     });
    // }
    /// filter Models
    EnrollmentWizardComponent.prototype.filterModels = function (searchModel) {
        if (typeof searchModel === 'object') {
            return this.models.filter(function (model) {
                return model.modelName.toLowerCase().indexOf(searchModel.modelName.toLowerCase()) === 0;
            });
        }
        else {
            return this.models.filter(function (model) {
                return model.modelName.toLowerCase().indexOf(searchModel.toLowerCase()) >= 0;
            });
        }
    };
    /// get carriers
    EnrollmentWizardComponent.prototype.getCarriers = function (modelId) {
        var _this = this;
        this.spinnerService.show();
        this.deviceInfoService.getCarriers(this.corporateEntityId, this.storeType, modelId)
            .subscribe(function (res) {
            if (res.length > 0) {
                // this.inputDataDevice.carriers = res;
                _this.carriers = res;
                // this.deviceInfoComponent.filteredCarriers = this.deviceInfoComponent.carrierCtrl.valueChanges
                //  .pipe(
                //    startWith(''),
                //    map(carrier => carrier ? this.filterCarriers(carrier) : this.carriers.slice())
                //  );
                _this.deviceInfoComponent.filteredCarriers = _this.carriers;
            }
            else {
                _this.dialogsService.confirm('Enrollment', Constants.noCarrierMsg);
            }
            _this.spinnerService.hide();
        });
    };
    /// filter carriers
    EnrollmentWizardComponent.prototype.filterCarriers = function (searchCarrier) {
        if (typeof searchCarrier === 'object') {
            return this.carriers.filter(function (carrier) {
                return carrier.carrierName.toLowerCase().indexOf(searchCarrier.carrierName.toLowerCase()) === 0;
            });
        }
        else {
            return this.carriers.filter(function (carrier) {
                return carrier.carrierName.toLowerCase().indexOf(searchCarrier.toLowerCase()) === 0;
            });
        }
    };
    /// upload ESN screenshot
    EnrollmentWizardComponent.prototype.uploadEsnScreenshot = function (esnFile) {
        var _this = this;
        if (this.validateFileType(esnFile.name.toLocaleLowerCase())) {
            if (!this.validateFileSize(esnFile)) {
                this.dialogsService.confirm('Enrollment', Constants.maximumFileUploadSizeExceededErrorMessage);
                this.deviceInfoComponent.esnUploadProgess = false;
            }
            else {
                this.deviceInfoService.uploadEsnScreenshot(esnFile)
                    .subscribe(function (res) {
                    _this.EnrollmentData.esnFileId = res.result;
                    _this.deviceInfoComponent.setEsnFileName(esnFile.name);
                });
            }
        }
        else {
            this.dialogsService.confirm('Enrollment', Constants.invalidFileType);
            this.deviceInfoComponent.esnUploadProgess = false;
        }
    };
    /// upload invoice file
    EnrollmentWizardComponent.prototype.uploadInvoiceFile = function (invoiceFile) {
        var _this = this;
        if (this.validateFileType(invoiceFile.name.toLocaleLowerCase())) {
            if (!this.validateFileSize(invoiceFile)) {
                this.dialogsService.confirm('Enrollment', Constants.maximumFileUploadSizeExceededErrorMessage);
                this.deviceInfoComponent.invoiceUploadProgess = false;
            }
            else {
                this.deviceInfoService.uploadInvoiceFile(invoiceFile)
                    .subscribe(function (res) {
                    _this.EnrollmentData.invoiceFileId = res.result;
                    _this.deviceInfoComponent.setInvoiceFileName(invoiceFile.name);
                });
            }
        }
        else {
            this.dialogsService.confirm('Enrollment', Constants.invalidFileType);
            this.deviceInfoComponent.invoiceUploadProgess = false;
        }
    };
    /// get product details
    EnrollmentWizardComponent.prototype.getProductDetails = function (modelId, carrierTypeId, cellularSrvFlag) {
        var _this = this;
        this.spinnerService.show();
        this.deviceInfoService.getProductDetails(this.corporateEntityId, this.storeType, modelId, carrierTypeId, cellularSrvFlag)
            .subscribe(function (res) {
            if (res) {
                if (Object.keys(res).length > 0) {
                    _this.EnrollmentData.ProductData = res;
                    _this.getPlanDetails(res.productCategoryId);
                }
                else {
                    _this.spinnerService.hide();
                    _this.dialogsService.confirm('Enrollment', Constants.noProductMsg);
                }
            }
            else {
                _this.spinnerService.hide();
                _this.dialogsService.confirm('Enrollment', Constants.noProductMsg);
            }
        });
    };
    /// get plan details
    EnrollmentWizardComponent.prototype.getPlanDetails = function (productCategoryId) {
        var _this = this;
        var stateAbbr = this.EnrollmentData.CustomerData.State.stateAbbr;
        var subcategoryId = this.EnrollmentData.AccessoryDeviceData.productCategoryId;
        this.deviceInfoService.getPlanDetails(this.corporateEntityId, stateAbbr, productCategoryId, subcategoryId, this.policyTypeId)
            .subscribe(function (res) {
            if (res.length > 0) {
                _this.EnrollmentData.PlanData = res;
                _this.deviceInfoComponent.goForward();
            }
            else {
                _this.dialogsService.confirm('Enrollment', Constants.noPlanMsg);
            }
            _this.spinnerService.hide();
        });
    };
    /// get exsisting cust state
    EnrollmentWizardComponent.prototype.getExsistingCustState = function (stateAbbr) {
        return this.states.find(function (state) { return state.stateAbbr === stateAbbr; });
    };
    /// enroll
    EnrollmentWizardComponent.prototype.enroll = function (event) {
        var _this = this;
        this.spinnerService.show();
        this.isStepEditable = false;
        this.enrollmentService.enroll(event)
            .subscribe(function (res) {
            var policyNumber;
            var isEnrollSuccess;
            var porductName;
            if (res) {
                if (res.length > 0) {
                    // this.confirmationComponent.policyNumber = res[0].PolicyNumber;
                    // this.confirmationComponent.isEnrollSuccess = true;
                    // this.confirmationComponent.porductName = this.EnrollmentData.AccessoryDeviceData.productName;
                    policyNumber = res[0].PolicyNumber;
                    isEnrollSuccess = true;
                    porductName = _this.EnrollmentData.AccessoryDeviceData.productName;
                }
                else {
                    // this.confirmationComponent.isEnrollSuccess = false;
                    isEnrollSuccess = false;
                }
            }
            else {
                // this.confirmationComponent.isEnrollSuccess = false;
                isEnrollSuccess = false;
            }
            _this.router.navigate(['/enrollment/confirmation'], {
                queryParams: {
                    'policyNumber': policyNumber,
                    'isEnrollSuccess': isEnrollSuccess,
                    'porductName': porductName
                }
            });
            _this.spinnerService.hide();
        });
    };
    /// cancel enrollment
    EnrollmentWizardComponent.prototype.cancelEnrollment = function () {
        this.router.navigate(['./home']);
    };
    /// open dialog
    EnrollmentWizardComponent.prototype.openDialog = function () {
        var _this = this;
        this.dialogsService
            .confirm('Confirm Dialog', Constants.areYouSureConfirmMessage)
            .subscribe(function (res) { return _this.result = res; });
    };
    /// reg step select
    EnrollmentWizardComponent.prototype.regStepSelect = function () {
        /* tslint:disable */
        console.log('res step selected');
        /* tslint:enable */
    };
    /// validate file size
    EnrollmentWizardComponent.prototype.validateFileSize = function (file) {
        if (file.size > 6291456) {
            return false;
        }
        else {
            return true;
        }
    };
    /// validate file type
    EnrollmentWizardComponent.prototype.validateFileType = function (name) {
        var ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
        if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'tiff'
            || ext === 'tif' || ext === 'pdf' || ext === 'bmp') {
            return true;
        }
        else {
            return false;
        }
    };
    /// wiz close warning message pop-up
    EnrollmentWizardComponent.prototype.wizCloseWarningMessagePopUp = function () {
        /// close warning pop-up messgage values set
        this.cbfwCloseWarningModalPopupComponent.title.DisplayValue = 'Enrollment';
        // this.cbfwCloseWarningModalPopupComponent.imageWarning.DisplayValue = 'sdfsd';
        this.cbfwCloseWarningModalPopupComponent.warningMessage.DisplayValue = Constants.enrollmentCancelConfirmationMsg;
        this.cbfwCloseWarningModalPopupComponent.show();
    };
    return EnrollmentWizardComponent;
}());
export { EnrollmentWizardComponent };
