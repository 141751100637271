/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./enrollment-mobile-device.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/cbfw-enrollment-mobile-device/cbfw-enrollment-mobile-device.ngfactory";
import * as i3 from "cbfw-enrollment-mobile-device";
import * as i4 from "./enrollment-mobile-device.component";
import * as i5 from "@angular/platform-browser";
var styles_EnrollmentMobileDeviceComponent = [i0.styles];
var RenderType_EnrollmentMobileDeviceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnrollmentMobileDeviceComponent, data: {} });
export { RenderType_EnrollmentMobileDeviceComponent as RenderType_EnrollmentMobileDeviceComponent };
export function View_EnrollmentMobileDeviceComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cbfwEnrollmentMobileDeviceComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "lib-enrollment-mobile-device", [], null, null, null, i2.View_CbfwEnrollmentMobileDeviceComponent_0, i2.RenderType_CbfwEnrollmentMobileDeviceComponent)), i1.ɵdid(2, 49152, [[1, 4]], 0, i3.CbfwEnrollmentMobileDeviceComponent, [], null, null)], null, null); }
export function View_EnrollmentMobileDeviceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-enrollment-mobile-device", [], null, null, null, View_EnrollmentMobileDeviceComponent_0, RenderType_EnrollmentMobileDeviceComponent)), i1.ɵdid(1, 114688, null, 0, i4.EnrollmentMobileDeviceComponent, [i5.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnrollmentMobileDeviceComponentNgFactory = i1.ɵccf("app-enrollment-mobile-device", i4.EnrollmentMobileDeviceComponent, View_EnrollmentMobileDeviceComponent_Host_0, {}, {}, []);
export { EnrollmentMobileDeviceComponentNgFactory as EnrollmentMobileDeviceComponentNgFactory };
