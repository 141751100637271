import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AppSettings } from '../models/app-settings';

@Injectable()
export class AppSettingsService {
  constructor(private http: HttpClient) {
  }

  getSettings(): Observable<AppSettings> {
    return this.http.get('./assets/app.config.json')
      .pipe(
        map(this.extractData),
        catchError(this.handleErrors));
  }

  private extractData(res: Response) {
    return res || {};
  }

  private handleErrors(error: any): Observable<any> {
    /* tslint:disable */  console.error('An error occurred', error); /* tslint:enable */
    return Observable.throw(error.message || error);
  }

}
