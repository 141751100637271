/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy-policy.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./privacy-policy.component";
import * as i3 from "../../services/app-device.service";
var styles_PrivacyPolicyComponent = [i0.styles];
var RenderType_PrivacyPolicyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivacyPolicyComponent, data: {} });
export { RenderType_PrivacyPolicyComponent as RenderType_PrivacyPolicyComponent };
export function View_PrivacyPolicyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "container-fluid p-20"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.privacyPolicy; _ck(_v, 0, 0, currVal_0); }); }
export function View_PrivacyPolicyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-privacy-policy", [], null, null, null, View_PrivacyPolicyComponent_0, RenderType_PrivacyPolicyComponent)), i1.ɵdid(1, 114688, null, 0, i2.PrivacyPolicyComponent, [i3.AppDeviceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivacyPolicyComponentNgFactory = i1.ɵccf("app-privacy-policy", i2.PrivacyPolicyComponent, View_PrivacyPolicyComponent_Host_0, {}, {}, []);
export { PrivacyPolicyComponentNgFactory as PrivacyPolicyComponentNgFactory };
