import { Constants } from './constants';

export class HtmlConstants {
    /* tslint:disable */

    public static readonly faqImageUrl = 'assets/images/cell-helmet/img-faq.jpg';

    public static readonly policyUnsettledClaimContent = `<div class="single-section">
<div class="row">
  <div class="col-xs-2 col-sm-2 col-md-2">
    <img src="assets/images/warning-msg-icon.png" class="img-responsive">
  </div>
  <div class="col-xs-10 col-sm-10 col-md-10">
  Filing a claim is restricted due to an existing claim.
  <br>For more details call ` + Constants.clientCallCenterPhoneNumber + `.
  </div>
  <div>
    <span></span>
  </div>
</div>
</div>`;

    public static readonly cbfwPolicyDetailsComponent =
        `<p>The registered device information must entirely match the device information you wish to file a claim on.</p>
   <p>If you have any questions, please contact us at ` + Constants.clientCallCenterPhoneNumber + `</p>`;


    public static readonly modalDevicePurchaseDateMessage = `<div class="terms-wrapper">
   <div class="single-section-main">
       <div class="single-section">
       <div class="row">
           <div class="col-md-12 col-sm-12 col-xs-12">
               <p>
                   You seem to have missed the 14 day deadline for registration and we are unable to proceed further.
                   Please check the purchase date for typos and try again. If that is not the case, please call `+ Constants.clientCallCenterPhoneNumber + ` for further information.
               </p>
           </div>
       </div>
       </div>
   </div>
   </div>`;

    public static readonly policyLookupErrorMessage = `
        <p>
            The value(s) entered by you does not correspond to an active policy in the system. Please confirm your information and try
            again.
        </p>        
        <br>
        <br>`;


    public static readonly faqContent =
        `<div class="single-section">
       <h4>What is covered under the warranty?</h4>
       <p>The Front Glass & LCD Screen are the only items covered in your warranty. </p>
   </div>
   <div class="single-section">
       <h4>What is NOT Covered under the warranty?</h4>
       <p>Any damage besides the front Glass/LCD will not be covered. Water Damage or Excessive physical damage will not be covered.</p>
   </div>
   <div class="single-section">
       <h4>When will my coverage begin?</h4>
       <p>Coverage begins 14 days from purchase upon valid enrollment.</p>
   </div>
   <div class="single-section">
       <h4>What will I need to register my Warranty Policy?</h4>
       <ul>
           <li>Your Personal Registration number located on the inside of the package.</li>
           <li>Proof of Purchase (Sales Receipt).</li>
           <li>Device Information (Make, Model, IMEI) - You will need to download and run the diagnostic application on your phone during registration.</li>
       </ul>
   </div>
   <div class="single-section">
       <h4>How do I file a Claim?</h4>
       <p>Visit <a href="/#/claim/policyLookup" target="_blank">www.InsuredGadgets.com/Warranty</a> to file a claim.</p>
   </div>
   <div class="single-section">
       <h4>Are there specific locations where I need to get my device repaired?</h4>
       <p>No, you can have your screen repaired at any repair facility of your choice. You will be required to provide a detailed copy of your repair receipt.</p>
   </div>   
   <div class="single-section">
       <h4>How many claims can I make?</h4>
       <p>You are allowed 1 claim for each product within 1 year of purchase.</p>
   </div>
   <div class="clear pad-top20"></div>`;


    public static readonly modalPrivacyPolicyMessage = `
    <p>The following conditions apply to every order placed on this website. Placing your order with us and/or sending/dropping off your device indicates your acceptance to these terms.</p>
    <p>This privacy notice discloses the privacy practices for insuredgadgets.com. This privacy notice applies solely to information collected by this web site. It will notify you of the following:</p>
    <ol>
    <li style="text-indent: -.25in;">What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.</li>
    <li style="text-indent: -.25in;">What choices are available to you regarding the use of your data.</li>
    <li style="text-indent: -.25in;">The security procedures in place to protect the misuse of your information.</li>
    <li style="text-indent: -.25in;">How you can correct any inaccuracies in the information.</li>
    </ol>
    <p>Information Collection, Use, and Sharing</p>
    <p>We are the sole owners of the information collected on this site. Only we have access to this information. We collect information that you voluntarily give us via email or through any other direct contact we have with you. We have no interest in this information and will not sell or rent this information to anyone. We will only use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as necessary to fulfill your request, i.e. to ship an order. Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.</p>
    <p>Your Access To and Control Over Information</p>
    <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via email or phone:</p>
    <ol>
    <li style="text-indent: -.25in;">See what data we have about you, if any.</li>
    <li style="text-indent: -.25in;">Change/correct any data we have about you.</li>
    <li style="text-indent: -.25in;">Have us delete any data we have about you.</li>
    <li style="text-indent: -.25in;">Express any concern you have about our use of your data.</li>
    </ol>
    <p>Orders</p>
    <p>We request information from you on our order form. To buy from us, you must provide contact information (like name and shipping address) and financial information (like credit card number, expiration date). This information is used for billing purposes and to fill your orders. If we have trouble processing an order, we will use this information to contact you.</p>
    <p>Cookies</p>
    <p>We use "cookies" on insuredgadgets.com. A cookie is a piece of data stored on a site visitor's hard disk to help us improve your access to the site and so we can identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in with a password more than once, thereby saving you time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.</p>
    <p>Links</p>
    <p>This web site, insuredgadgets.com, contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of other sites. We encourage our users to be aware of this when they leave our site and to read the privacy statements of any other site that collects personal information.</p>
    <p>Security</p>
    <p>We take precautions to protect your information. When you submit sensitive information through our website, your information is protected both online and offline. Wherever we collect sensitive information (such as credit card data), that information is encrypted and transmitted in a secure way. You can verify this by looking for a closed lock icon at the bottom of your web browser, or looking for "https" at the beginning of the address of the web page. While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers and servers in which we store personally identifiable information are kept in a secure environment.</p>
    <p>If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 1-877-551-0432 - or via email at mywarranty@insuredgadgets.com.</p>
    <p>Privacy notice discloses the privacy practices for insuredgadgets.com. This privacy notice applies solely to information collected by this web site. It will notify you of the following:</p>
    <ol>
    <li style="text-indent: -.25in;">What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.</li>
    <li style="text-indent: -.25in;">What choices are available to you regarding the use of your data.</li>
    <li style="text-indent: -.25in;">The security procedures in place to protect the misuse of your information.</li>
    <li style="text-indent: -.25in;">How you can correct any inaccuracies in the information.</li>
    </ol>`;

    public static readonly registrationInfoModalMessage =
        `<div class="terms-wrapper">
            <div class="single-section-main">
            <div class="single-section">
            <div align="center">                    
                    <p></p>
                    <img src="assets/images/cell-helmet/serial_number_information_image-sw1.png">
                    </div>
                </div>
            </div>
        </div>
    `;

    public static readonly modalTermsAndConditionsMessage = 
        `<img src="assets/images/cell-helmet/cellhelmet-mobile-popup02.png">`;
    
    public static readonly modalTermsAndConditionsTitle = 'Information';

    public static readonly modalContactUsMessage =
        `<dl>
        <dt>Customer Service:</dt>
        <dd>Monday - Saturday 6:00 am - 9:00 pm Pacific Standard Time</dd>
        <dd>Sunday 8:00 am - 7:00 pm Pacific Standard Time</dd>
        <dd>`+ Constants.clientEmail + `</dd>
        <dd>` + Constants.clientCallCenterPhoneNumber + `</dd>        
      </dl>
      <p>If you have any inquiries, please contact Customer Service at ` + Constants.clientCallCenterPhoneNumber + `. For additional info or comments,
        please click the “Email” button and fill out the form below.</p>`;


    public static readonly customerComponentNote = `
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <p>
            <small>
                The email specified already corresponds to a customer in the system. If you continue with this email , the system will create
                the policy against the customer information that corresponds to the above email.
            </small>
        </p>
        <p>
            <small>You may change the email and try again.</small>
        </p>
        <p>
            <small>If you have any questions please call ` + Constants.clientCallCenterPhoneNumber + ` for assistance.</small>
        </p>
    </div>`

    public static readonly noticeText = '';

    public static readonly searchPolicyComponentNote = `<span>NOTE:</span>
    <ol>        
        <li>Only the account holder may file the claim.</li>
        <li>Claims will only be supported for physical damage excluding fire and water damage, or excessive misuse of the product/device.</li>
        <li>Submitting a claim within the 12 month warranty period will exhaust the warranty.</li>
        <li>To file a claim, the answers to the two questions below are required.</li>
    </ol>
    <p>All fields marked with an asterisk (*) are required.</p>`;

    public static readonly heroBannerNotice = `
    <div class="row banner-notice">
    <div class="col-md-6 col-sm-12 col-12 ng-star-inserted mt-3 px-3 pt-3 pb-0 text-dark bg-light border border-dark rounded-0">
    <p class="font-italic text-justify ng-star-inserted banner-notice-txt">If you are filing a claim with a registration code that starts with OB, please go to <a href="https://www.cellhelmet.com" class="text-primary font-italic" target="_blank">www.cellhelmet.com</a> under Claims Directory and choose 10 Character Pin starting with OB to file your claim or contact cellhelmet at 412-610-0692.</p>
    </div>
    </div>
    <div class="col-md-6">&nbsp;</div>`;

    public static readonly lookuplNotice = `<p class="font-italic text-justify ng-star-inserted">If you are filing a claim with a registration code that starts with OB, please go to 
    <a href="https://www.cellhelmet.com" class="text-primary font-italic" target="_blank">www.cellhelmet.com</a> under Claims Directory and choose 10 Character Pin starting with OB to file your claim or contact cellhelmet at 412-610-0692.</p>`;

    /* tslint:enable */

}
