import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpErrorHandler } from './http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { EnvConfig } from './app-env-config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-env-config";
import * as i3 from "./http-error-handler.service";
// declare var google: any;
var AddressSuggestionService = /** @class */ (function () {
    function AddressSuggestionService(http, envConfig, httpErrorHandler) {
        this.http = http;
        this.envConfig = envConfig;
        this.httpErrorHandler = httpErrorHandler;
        this.apiUrlEnrollment = this.envConfig.get('apiUrlEnrollment');
        this.handleError = httpErrorHandler.createHandleError('StateService');
    }
    AddressSuggestionService.prototype.loadAddressInformation = function (address) {
        return this.http.get(this.apiUrlEnrollment + '/v2/customer/GetAddressInformation?address=' +
            address)
            .pipe(catchError(this.handleError('getAddressSuggestion', [])));
    };
    AddressSuggestionService.prototype.getAddressSuggestion = function (address) {
        return this.http.get(this.apiUrlEnrollment + '/v2/customer/GetAddressSuggestionsByInput?addressInfo=' +
            address)
            .pipe(catchError(this.handleError('getAddressSuggestion', [])));
    };
    AddressSuggestionService.ngInjectableDef = i0.defineInjectable({ factory: function AddressSuggestionService_Factory() { return new AddressSuggestionService(i0.inject(i1.HttpClient), i0.inject(i2.EnvConfig), i0.inject(i3.HttpErrorHandler)); }, token: AddressSuggestionService, providedIn: "root" });
    return AddressSuggestionService;
}());
export { AddressSuggestionService };
