import { Controller } from 'cbfw-menu';

export class Constants {

    public static readonly siteName = 'cellhelmet';
    public static readonly clientPhoneNumber = '312-860-8082';
    public static readonly clientCallCenterPhoneNumber = '844-651-2320';
    public static readonly clientEmail = 'info@cellbrokerage.com';
    public static readonly areYouSureConfirmMessage = 'Are you sure you want to do this?';

    public static readonly homeRoute = '/home';
    public static readonly claimFilingRoute = '/claim/claimFiling';
    public static readonly policyLookupRoute = '/claim/policyLookup';
    public static readonly policyDetailsRoute = '/claim/policyDetails';
    public static readonly enrollmentEnrollRoute = '/enrollment/enroll';
    public static readonly enrollmentMobileDeviceRoute = '/enrollment/mobileDevice';
    public static readonly enrollmentDeviceTypeRoute = '/enrollment/deviceType';

    public static readonly appDeviceKey = 'app_device';

    public static readonly playStoreUrl =
        'https://install.appcenter.ms/users/naveen.dissanayake/apps/limited-warranty-android/distribution_groups/uat';

    public static readonly appStoreUrl =
        'https://install.appcenter.ms/orgs/cellcrokerage/apps/grip-limited-warranty/distribution_groups/qa';

    // ------------hero banner-----------------------
    public static readonly heroBannerText = `
    In order to file a claim, you must have a valid registration completed to
    it's entirety within the warranty terms and conditions. If you have already
     done so, please click below to initiate your claim. If you are unsure,
      please contact cellhelmet customer service at customer.service@cellhelmet.com
       to confirm your registration.`;

    // -------------Claim Process---------------------------

    public static readonly steperItemThreeLabel = 'Address Information';

    // -------claim-confirmation-componenet---------------------

    public static readonly policyLookupSearchItems = [
        {
          value: 'ActivationCode',
          viewValue: 'PIN Code',
          errorMessage: `PIN Code is <strong>required</strong>`
        },
        {
          value: 'PolicyNumber',
          viewValue: 'Policy Number',
          errorMessage: `Policy Number is <strong>required</strong>`
        },
        {
          value: 'ESN',
          viewValue: 'IMEI/ESN/Serial Number',
          errorMessage: `IMEI/ESN/Serial Number is <strong>required</strong>`
        }
      ];

      public static readonly policyLookupTextBoxDefaultValue = 'PIN Code';

    public static readonly claimConfirmationSuccessMessage =
        `Thank you for your submission. Your claim is being reviewed. Please allow 2 business days for a response.
If you have any questions, please contact us at
 ` + Constants.clientCallCenterPhoneNumber + ` and reference your claim number`;

    public static readonly claimConfirmationFailMessage =
        `There was an error while processing your information. Please try again shortly.
     <br/> If the problem persists, please call us at ` + Constants.clientCallCenterPhoneNumber + ` for assistance.`;

     public static readonly claimDeductableReceiptText = 'Do you have a copy of your repair receipt?';

     public static readonly labelDeductibleReceiptText = `In order to file a claim, your itemized
     receipt of repair or insurance deductible is required. You will need to upload a copy of this
     receipt to proceed with your claim request.`;


    // ------------claim-wizard-component-----------------

    public static readonly labelTitle = 'Address Information';

    public static readonly textBoxHowDeviceDamagedLabel = `Please describe how the damage
     to the device occurred in as much detail as possible.`;

    public static readonly modalCostOfRepairContentLabel = `The cost of repair/deductible paid that was entered
    is greater than the amount of warranty coverage purchased. Under the warranty terms and conditions,
     you will only be reimbursed up to the amount of repair/deductible paid. This number may not exceed
      the amount of warranty coverage purchased `;

    public static readonly repairCostDecimalValue = false;

    public static readonly labelWhereDoYou = `Please enter the address you wish the reimbursement
     check to be delivered to.`;

    public static readonly labelMaximuReimbursemenAmount = 'The maximum reimbursement amount entered may not exceed $';

    public static readonly labelYouMustEnter = `Please provide an alternative
     email address and contact number to proceed.`;

    public static readonly labelNote =  `Please note: This is not a confirmation
     of reimbursement. The reimbursement check will be delivered in approximately
      2-4 weeks upon approval of the claim.`;

    public static readonly reimbursementAmountErrorMessage =
        'Error occurred while getting Reimbursement Amount.';

    public static readonly maximumFileUploadSizeExceededErrorMessage =
    'Maximum file upload size is exceeded (Max File Size : 6MB)';

    public static readonly invalidFileType = 'Invalid file type';

    public static readonly invoiceComponenetLabelNote = `Note: A claim can only be filed if a device
    has been repaired or a replacement claim has been filed,
   thus it is mandatory to upload a repair receipt or a claim deductible receipt. `;

    // --------------policy-lookup.component--------------
    public static readonly textBoxEmailLabel = 'Please enter the email address used for registration';

    public static readonly radioButtonRegisteredProductLabel = 'Have you registered your cellhelmet product?';

    public static readonly checkDeliveryLabelNoteDisplayValue =
    `Please note: This is not a confirmation of reimbursement. The reimbursement
    check will be delivered in approximately 2-4 weeks upon approval of the claim.`;

    public static readonly labelRegisteredProduct = `
    In order to process a claim, please download the cellhelmet application and
     register your product within 30 days from the date of purchase. The device
      must be free from damage upon date of registration.`;

  // -------------Enrollment Process---------------------------

  // ---------------Enrollment Wizard --------------------

  public static readonly enrollmentConfirmationFailMessage = `The policy creation failed due to an unknown error.
  Please try again shortly.   If the problem persists or for further
  information, please call ` + Constants.clientCallCenterPhoneNumber + `.`;

  public static readonly enrollmentSummaryText = `If you need to file a warranty claim,
  please log into www.InsuredGadgets.com/Warranty and click the "File a Claim" link in the upper
  right hand corner.`;

  public static readonly deviceEnrollmentLabelText = `Other - Enrollment of devices other than mobile phones`;

  public static readonly enrollMobileDeviceDisplayMessage =
  `Enrollment of mobile phones are done through the cellhelmet application.
 Diagnostic procedures must be completed using the app. Please download the app to your mobile phone to begin.`;


    public static readonly regComponentPleaseContactMessage =
    'Please contact our customer service department for assistance at ' + Constants.clientCallCenterPhoneNumber + '.';


    public static readonly validateRegistrateCodePleaseContactMsg =
    'Please contact our customer service department for assistance at ' + Constants.clientCallCenterPhoneNumber + '.';

    public static readonly validateRegstrationCodeErrorMsg =
    `We are unable to locate the Registration number provided by you.
    Please check the Registration number and try again.`;

    public static readonly validateEsnConfirmationMsg =
    'IMEI / ESN / Serial Number is aready exist with an active policy';

    public static readonly noCarrierMsg =
    'No Carriers available for this model';

    public static readonly noProductMsg =
    'No product available';

    public static readonly noPlanMsg =
    'No Plans available';

    public static readonly enrollmentCancelConfirmationMsg =
    `You are about to cancel the enrollment process. If you proceed, already entered data will be discarded.
         Cancel the current enrollment process?`;

    public static readonly enrollmentConfirmationMessage =
    `Your registration request is under review. Your benefits are subject to the product terms and conditions.`;


    // ---------------------Common------------------------------------------

    public static readonly heroBannerHeader = 'Claim';

    public static readonly buttonHeroBanner = 'File a claim';

    public static readonly heroBannerButtonLinkedURL = '/claim/policyLookup';

    public static readonly menuItems: Controller[] = [
        { DisplayValue: 'Home', LinkedUrl: '/home'},
        { DisplayValue: 'File a Claim', LinkedUrl: '/claim/policyLookup'}
        // { DisplayValue: 'FAQs', LinkedUrl: '/faq'}
      ];

    public static readonly modalSearchPolicyMessage = `
    Your search criteria returned the following registered policies.
    Please select the policy you wish to file a claim on.`;

    public static readonly modalContactInfoMessage = `
    If you have any questions, please contact us at ` + Constants.clientCallCenterPhoneNumber;

    public static readonly quicklinksTitleIsVisible = false;

    public static readonly quickLinksPrivacyPolicyIsVisible = false;

    public static readonly quickLinksTermsConditionsIsVisible = false;

    public static readonly quickLinksContactUsIsVisible = false;

    public static readonly allowRouteToEnrollment = false;

    public static readonly heroBannerTextQuestionVisible = false;

    public static readonly heroBannerLinkFaqsVisisble = false;

    public static readonly heroBannerNoticeVisisble = true;

    public static readonly showCategoryListDropDownForIgr = false;

    public static readonly sessionProductCategoryVariableName = 'selectedCategoryId';

    // Added this on the User Story requirement 4973 | 2020-05-13
    public static readonly showImeiField = true;

    public static readonly showNotice = false;

    public static readonly policyLookuplNoticeVisisble = true;
}


