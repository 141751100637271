import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimDataManagementService } from '../../services/claim-data-management.service';
import { ViewChild } from '@angular/core';
import { CbfwClaimConfirmationComponent } from 'cbfw-claim-confirmation';
import { Constants } from '../../../shared/utilities/constants';
import { LocalStorageService } from '../../../shared/services/local-storage.service';
import { AppDeviceService } from '../../../shared/services/app-device.service';

@Component({
  selector: 'app-claim-confirmation',
  templateUrl: './claim-confirmation.component.html',
  styleUrls: ['./claim-confirmation.component.css']
})
export class ClaimConfirmationComponent implements OnInit {

  @ViewChild(CbfwClaimConfirmationComponent) claimConfirmationComponent: CbfwClaimConfirmationComponent;

  constructor(
    private router: Router,
    private claimDataManagementService: ClaimDataManagementService,
    private appDeviceService: AppDeviceService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.claimConfirmationComponent.successMessage.DisplayValue = Constants.claimConfirmationSuccessMessage;

    this.claimConfirmationComponent.failMessage.DisplayValue = Constants.claimConfirmationFailMessage;

  }

  dataLoad() {
    this.claimDataManagementService.ClaimId.subscribe(data => {
      this.claimConfirmationComponent.claimNmber = data.trim();
    });
    this.claimDataManagementService.ClaimSuccessStattus.subscribe(status => {
      this.claimConfirmationComponent.successStatus = status;
    });
  }

  close() {
    const appDevice = this.localStorageService.getItem(Constants.appDeviceKey);
    if (this.appDeviceService.isAppDeviceAndroidOrIos(appDevice)) {
      this.router.navigate([Constants.policyLookupRoute]);
    } else {
      this.router.navigate([Constants.homeRoute]);
    }
  }

}
