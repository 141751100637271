import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AppSettings } from '../../models/app-settings';
import { AppSettingsService } from '../../services/app-settings-service';
import { Title } from '@angular/platform-browser';
import { Constants } from '../../utilities/constants';
import { HeroBannerComponent } from 'cbfw-hero-banner';
import { HtmlConstants } from '../../utilities/html-constants';

declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  settings: AppSettings;
  heroBanner: any;

  @ViewChild(HeroBannerComponent) heroBannerComponent: HeroBannerComponent;
  constructor(
    private appSettingsService: AppSettingsService,
    private titleService: Title) {
    this.titleService.setTitle(Constants.siteName + ' | Home');
  }

  ngOnInit() {
    this.appSettingsService.getSettings()
      .subscribe(settings => this.settings = settings);

      $('body').css('margin-bottom', '160px');
      this.heroBannerComponent.textQuestions.IsVisible = Constants.heroBannerTextQuestionVisible;
      this.heroBannerComponent.linkFaqs.IsVisible = Constants.heroBannerLinkFaqsVisisble;
      this.heroBannerComponent.bannerText.DisplayValue = Constants.heroBannerText;
      this.heroBannerComponent.bannerHeader.DisplayValue = Constants.heroBannerHeader;
      this.heroBannerComponent.buttonHeroBanner.DisplayValue = Constants.buttonHeroBanner;
      this.heroBannerComponent.buttonHeroBanner.LinkedUrl = Constants.heroBannerButtonLinkedURL;
      this.heroBannerComponent.bannerNotice.IsVisible = Constants.heroBannerNoticeVisisble;
      this.heroBannerComponent.bannerNotice.DisplayValue = HtmlConstants.heroBannerNotice;

      // this.heroBannerComponent.bannerHeader.IsVisible = false;
      // this.heroBannerComponent.buttonHeroBanner.IsVisible = false;
      // this.heroBannerComponent.textLookupCustomer.IsVisible = false;
     // this.heroBannerComponent.textQuestions.IsVisible = false;
  }

}
