import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvConfig } from '../services/app-env-config';
import { Store } from '../models/store';

@Injectable()
export class DeviceInfoService {

  apiUrlMaster;
  apiUrlEnrollment;
  corporateEntityCode;

  private handleError: HandleError;

  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private envConfig: EnvConfig) {
    this.handleError = httpErrorHandler.createHandleError('DeviceInfoService');

    this.apiUrlMaster = this.envConfig.get('apiUrlMaster');
    this.apiUrlEnrollment = this.envConfig.get('apiUrlEnrollment');
    this.corporateEntityCode = this.envConfig.get('corporateEntityCode');
  }


  getManufacturers(corporateEntityId, storeType, cellularSrvFlag, deviceCategoryId ?: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('corporateEntityId', corporateEntityId)
        .set('storeType', storeType)
        .set('cellularSrvFlag', cellularSrvFlag)
        .set('categoryId', deviceCategoryId)
    };

    return this.http.get<any>(this.apiUrlMaster + '/Product/GetManufacturers', httpOptions)
      .pipe(
        catchError(this.handleError('getManufacturers', []))
      );
  }

  getModels(manufacturerId, corporateEntityId, storeType, cellularSrvFlag): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('manufacturerId', manufacturerId)
        .set('corporateEntityId', corporateEntityId)
        .set('storeType', storeType)
        .set('cellularSrvFlag', cellularSrvFlag)
    };
    return this.http.get<any>(this.apiUrlMaster + '/v2/Product/GetNonMobileModels', httpOptions)
      .pipe(
        catchError(this.handleError('getModels', []))
      );
  }

  getCarriers(corporateEntityId, storeType, modelId): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('corporateEntityId', corporateEntityId)
        .set('storeType', storeType)
        .set('modelId', modelId)
    };

    return this.http.get<any>(this.apiUrlMaster + '/Product/GetCarriers', httpOptions)
      .pipe(
        catchError(this.handleError('getCarriers', []))
      );
  }

  uploadEsnScreenshot(esnFile: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', esnFile);

    return this.http.post(this.apiUrlEnrollment + '/Enrollment/UploadScreenShot', formData)
      .pipe(
        catchError(this.handleError('uploadEsnScreenshot', []))
      );
  }

  uploadInvoiceFile(esnFile: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', esnFile);

    return this.http.post(this.apiUrlEnrollment + '/Enrollment/UploadInvoice', formData)
      .pipe(
        catchError(this.handleError('uploadInvoiceFile', []))
      );
  }


  getProductDetails(corporateEntityId, storeType, modelId, carrierTypeId, cellularSrvFlag): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('corporateEntityId', corporateEntityId)
        .set('storeType', storeType)
        .set('modelId', modelId)
        .set('carrierTypeId', carrierTypeId)
        .set('cellularSrvFlag', cellularSrvFlag)
    };

    return this.http.get<any>(this.apiUrlMaster + '/Product/GetProductDetails', httpOptions)
      .pipe(
        catchError(this.handleError('getProductDetails', []))
      );
  }

  getPlanDetails(corporateEntityId, stateAbbr, productCategoryId, subcategoryId, policyTypeId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'CorporateEntityCode': this.corporateEntityCode
      }),
      params: new HttpParams()
        .set('corporateEntityId', corporateEntityId)
        .set('stateAbbr', stateAbbr)
        .set('categoryId', productCategoryId)
        .set('subcategoryId', subcategoryId)
        .set('policyTypeId', policyTypeId)
    };

    return this.http.get<any>(this.apiUrlEnrollment + '/Enrollment/GetScheduledPlans', httpOptions)
      .pipe(
        catchError(this.handleError('getPlanDetails', []))
      );
  }

  getValidateEsn(corporateEntityId, Esn): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .set('esn', Esn)
        .set('corporateEntityId', corporateEntityId)
    };

    return this.http.get<any>(this.apiUrlEnrollment + '/Enrollment/IsEsnExistsInActivePolicies', httpOptions)
      .pipe(
        catchError(this.handleError('getValidateEsn', []))
      );
  }

  getStores(state, corporateEntityId): Observable<Store[]> {
    const httpOptions = {
      params: new HttpParams()
        .set('corporateEntityId', corporateEntityId)
        .set('stateId', state.stateId)
    };
    return this.http.get<Store[]>(this.apiUrlMaster + '/Stores/GetStoresByCorporateEntityAndState', httpOptions)
      .pipe(
        catchError(this.handleError('getStores', []))
      );
  }

  // Service to get Devices Category List
  getDeviceCategoryList(): Observable<any[]> {

    const corporateEntityId = this.envConfig.get('corporateEntityId');
    const httpOptions = { params: new HttpParams()
                          .set('corporateEntityId', corporateEntityId)
                        };

    return this.http.get<any[]>(this.apiUrlMaster + '/Product/GetProductCategories', httpOptions)
      .pipe(
        catchError(this.handleError('Get Category List', []))
      );
  }
}
