/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./policy-lookup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/cbfw-policy-lookup/cbfw-policy-lookup.ngfactory";
import * as i3 from "cbfw-policy-lookup";
import * as i4 from "@angular/router";
import * as i5 from "@angular/forms";
import * as i6 from "./policy-lookup.component";
import * as i7 from "../../../shared/services/app-env-config";
import * as i8 from "../../services/policy-lookup.service";
import * as i9 from "ng4-loading-spinner";
import * as i10 from "../../services/claim-data-management.service";
import * as i11 from "../../../shared/services/app-device.service";
import * as i12 from "../../../shared/services/local-storage.service";
import * as i13 from "@angular/platform-browser";
var styles_PolicyLookupComponent = [i0.styles];
var RenderType_PolicyLookupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PolicyLookupComponent, data: {} });
export { RenderType_PolicyLookupComponent as RenderType_PolicyLookupComponent };
export function View_PolicyLookupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cbfwPolicyLookupComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "lib-policy-lookup", [], null, [[null, "policySearch"], [null, "cancelClick"], [null, "selectPolicy"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("policySearch" === en)) {
        var pd_0 = (_co.searchPolicy($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelClick" === en)) {
        var pd_1 = (_co.cancelClick() !== false);
        ad = (pd_1 && ad);
    } if (("selectPolicy" === en)) {
        var pd_2 = (_co.selectPolicy($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_CbfwPolicyLookupComponent_0, i2.RenderType_CbfwPolicyLookupComponent)), i1.ɵdid(2, 4308992, [[1, 4]], 0, i3.CbfwPolicyLookupComponent, [i4.Router, i5.FormBuilder, i1.ElementRef], null, { policySearch: "policySearch", cancelClick: "cancelClick", selectPolicy: "selectPolicy" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_PolicyLookupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-policy-lookup", [], null, null, null, View_PolicyLookupComponent_0, RenderType_PolicyLookupComponent)), i1.ɵdid(1, 114688, null, 0, i6.PolicyLookupComponent, [i7.EnvConfig, i8.PolicyLookupService, i9.Ng4LoadingSpinnerService, i10.ClaimDataManagementService, i4.Router, i4.ActivatedRoute, i11.AppDeviceService, i12.LocalStorageService, i13.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PolicyLookupComponentNgFactory = i1.ɵccf("app-policy-lookup", i6.PolicyLookupComponent, View_PolicyLookupComponent_Host_0, {}, {}, []);
export { PolicyLookupComponentNgFactory as PolicyLookupComponentNgFactory };
