import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators/';
import { Observable } from 'rxjs';
import { interval } from 'rxjs';

import { PolicyLookupService } from '../../services/policy-lookup.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StateService } from '../../../shared/services/state.service';
import { EnvConfig } from '../../../shared/services/app-env-config';
import { ClaimData } from '../../models/claim-data.model';
import { ClaimDataManagementService } from '../../services/claim-data-management.service';
import { InvoiceService } from '../../services/invoice.service';
import { ClaimService } from '../../services/claim.service';
import { CheckDeliveryService } from '../../services/check-delivery.service';
import { CbfwIncidentDetailsStepComponent } from 'cbfw-incident-details-step';
import { CbfwInvoiceStepComponent } from 'cbfw-invoice-step';
import { CbfwConfirmDialogComponent, CbfwConfirmDialogService } from 'cbfw-confirm-dialog';
import { CbfwCheckDeliveryStepComponent } from 'cbfw-check-delivery-step';
import { CbfwClaimSummaryStepComponent } from 'cbfw-claim-summary-step';
import { Constants } from '../../../shared/utilities/constants';
import { CbfwModalPopupComponent, ModalType } from 'cbfw-modal-popup';
import { CbfwCloseWarningModalPopupComponent } from 'cbfw-close-warning-modal-popup';
import { AppDeviceService } from '../../../shared/services/app-device.service';
import { LocalStorageService } from '../../../shared/services/local-storage.service';

@Component({
  selector: 'app-claim-wizard',
  templateUrl: './claim-wizard.component.html',
  styleUrls: ['./claim-wizard.component.css']
})
export class ClaimWizardComponent implements OnInit {

  // incidentFormGroup: FormGroup;
  incidentDetailsFormGroup: FormGroup;
  invoiceFormGroup: FormGroup;
  checkDeliveryFormGroup: FormGroup;
  claimSummaryFormGroup: FormGroup;

  isLinear = false;

  @ViewChild(CbfwInvoiceStepComponent) invoiceComponent: CbfwInvoiceStepComponent;
  @ViewChild(CbfwCheckDeliveryStepComponent) checkDeliveryComponent: CbfwCheckDeliveryStepComponent;
  @ViewChild(CbfwClaimSummaryStepComponent) claimSummaryComponent: CbfwClaimSummaryStepComponent;
  @ViewChild(CbfwIncidentDetailsStepComponent) incidentDetailsComponent: CbfwIncidentDetailsStepComponent;
  @ViewChild(CbfwCloseWarningModalPopupComponent)
  cbfwCloseWarningModalPopupComponent: CbfwCloseWarningModalPopupComponent;

  // hold the data through the caim flow
  claimData: ClaimData = new ClaimData;

  states: any = {};
  corporateEntityId;
  maincategoryId;
  subcategoryId;
  steperItemThree;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private cbfwConfirmDialogService: CbfwConfirmDialogService,
    private spinnerService: Ng4LoadingSpinnerService,
    private stateService: StateService,
    private envConfig: EnvConfig,
    private claimDataManagementService: ClaimDataManagementService,
    private invoiceService: InvoiceService,
    private claimService: ClaimService,
    private checkDeliveryService: CheckDeliveryService,
    private appDeviceService: AppDeviceService,
    private localStorageService: LocalStorageService
  ) {

    // this.corporateEntityId = this.envConfig.get('corporateEntityId');
    this.corporateEntityId = localStorage.getItem('corporateEntityId');
    this.maincategoryId = this.envConfig.get('maincategoryId');
    this.subcategoryId = this.envConfig.get('subcategoryId');
    this.steperItemThree = Constants.steperItemThreeLabel;
  }

  ngOnInit() {

    this.incidentDetailsFormGroup = this._formBuilder.group({
    });
    this.invoiceFormGroup = this._formBuilder.group({
    });
    this.checkDeliveryFormGroup = this._formBuilder.group({
    });
    this.claimSummaryFormGroup = this._formBuilder.group({
    });

    this.incidentDetailsComponent.labelYouMustEnter.DisplayValue = Constants.labelYouMustEnter;

    this.invoiceComponent.labelMaximuReimbursemenAmount.DisplayValue = Constants.labelMaximuReimbursemenAmount;

    this.invoiceComponent.modalCostOfRepairContent.DisplayValue = Constants.modalCostOfRepairContentLabel;

    this.invoiceComponent.repairCostDecimalValue.IsVisible = Constants.repairCostDecimalValue;

    this.incidentDetailsComponent.textBoxHowDeviceDamaged.DisplayValue = Constants.textBoxHowDeviceDamagedLabel;

    this.checkDeliveryComponent.labelTitle.DisplayValue = Constants.labelTitle;

    // Modification done according the user story 4973 | 2020-05-13
    this.claimSummaryComponent.tableColumnIMEI.IsVisible = Constants.showImeiField;

    this.isLinear = true;

    this.claimDataManagementService.ClaimData.subscribe(data => {

      if (data.PolicyData) {
        this.claimData = data;
        this.getStates();
      } else {
        // this.router.navigate (['/claim/policyLookup']);
        this.router.navigate([Constants.policyLookupRoute]);
      }
    });

    this.claimSummaryComponent.tableColumnManufacturer.IsVisible = false;
    this.checkDeliveryComponent.labelNote.DisplayValue = Constants.labelNote;
    this.invoiceComponent.labelNote.DisplayValue = Constants.invoiceComponenetLabelNote;
    this.checkDeliveryComponent.labelNote.DisplayValue = Constants.checkDeliveryLabelNoteDisplayValue;
    this.checkDeliveryComponent.labelWhereDoYou.DisplayValue = Constants.labelWhereDoYou;
  }

  invoiceStepHomeClick() {
    // this.router.navigate(['/home']);
    this.router.navigate([Constants.homeRoute]);
  }


  incidentDetailsFormEventHandler(event: FormGroup) {
    this.incidentDetailsFormGroup = event;
  }

  invoiceFormEventHandler(event: FormGroup) {
    this.invoiceFormGroup = event;
  }
  checkDeliveryFormEventHandler(event: FormGroup) {
    this.checkDeliveryFormGroup = event;
  }

  claimSummaryFormEventHandler(event: FormGroup) {
    this.claimSummaryFormGroup = event;
  }
  setIncidentDetailsData(event) {
    this.spinnerService.show();
    this.claimData.IncidentDetails = event;
    const email = this.claimData.IncidentDetails.AlternativeEmail;

    if (email) {
      this.claimService.validateEmailEmailReturnPath(email)
        .subscribe(res => {
          this.spinnerService.hide();
          if (res && !res.IsValid) {
            // Set return path email validation error
            this.incidentDetailsComponent.setInvalidEmail();

          } else {

            this.getReimbursementAmount();
          }
        });
    } else {
      this.getReimbursementAmount();
    }

  }

  setCheckDeliveryData(event) {
    this.claimData.CheckDeliveryData = event;
  }

  setInvoiceData(event) {
    this.claimData.InvoiceData = event;

    // Geting customer details
    const customerId = this.claimData.PolicyData.PolicyCustomerId;
    this.spinnerService.show();
    this.checkDeliveryService.getShippingDetails(customerId)
      .subscribe(res => {
        this.spinnerService.hide();
        this.claimData.CustomerData = res;

        // Adding State object to use in check-delivery step
        this.claimData.CustomerData.State = this.states.find(
          state => state.stateAbbr === this.claimData.CustomerData.CustomerStateAbbr),

          this.checkDeliveryComponent.onStepLoad(this.claimData.CustomerData);
      });
  }

  getReimbursementAmount() {
    const productId = this.claimData.PolicyData.PolicyProductId;
    this.spinnerService.show();
    this.invoiceService.getReimbursementAmount(productId, this.corporateEntityId)
      .subscribe(res => {
        if (Object.keys(res).length === 0) {
          this.cbfwConfirmDialogService.confirm('Claim', Constants.reimbursementAmountErrorMessage);
        } else {
          this.claimData.CoveragePlan = res;
          this.incidentDetailsComponent.goToNextStep();
        }
        this.spinnerService.hide();
      });
  }

  getStates(): void {
    this.spinnerService.show();
    this.stateService.getStates()
      .subscribe(res => {
        this.states = res;
        this.spinnerService.hide();

        this.checkDeliveryComponent.filteredStates = this.states;
      });
  }

  filterStates(name: any) {
    if (typeof name === 'object') {
      return this.states.filter(state =>
        state.stateName.toLowerCase().indexOf(name.stateName.toLowerCase()) === 0);
    } else {
      return this.states.filter(state =>
        state.stateName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    }

  }

  uploadRepairReceipt(receiptFile: File) {
    if (this.validateFileType(receiptFile.name.toLocaleLowerCase())) {
      if (!this.validateFileSize(receiptFile)) {
        this.cbfwConfirmDialogService.confirm('Claim', Constants.maximumFileUploadSizeExceededErrorMessage);
        this.invoiceComponent.receiptUploadProgess = false;
      } else {
        // this.invoiceComponent.setReceiptFileName(receiptFile.name, '1');
        this.invoiceService.uploadRepairReceipt(receiptFile)
          .subscribe(res => {
            if (res.result) {
              this.invoiceComponent.setReceiptFileName(receiptFile.name, res.result);

            }
          });
      }
    } else {
      this.cbfwConfirmDialogService.confirm('Claim', Constants.invalidFileType);
      this.invoiceComponent.receiptUploadProgess = false;
    }

  }

  uploadDmagedScreen(uploadData) {

    if (this.validateFileType(uploadData.file.name.toLocaleLowerCase())) {
      if (!this.validateFileSize(uploadData.file)) {
        this.cbfwConfirmDialogService.confirm('Claim', Constants.maximumFileUploadSizeExceededErrorMessage);
        this.invoiceComponent.damagedScreenUploadProgess = false;
      } else {
        this.invoiceService.uploadDmagedScreen(uploadData.file, uploadData.policyId)
          .subscribe(res => {
            this.invoiceComponent.setDamagedScreenFileName(uploadData.file.name, res.result);
          });
      }
    } else {
      this.cbfwConfirmDialogService.confirm('Claim', Constants.invalidFileType);
      this.invoiceComponent.damagedScreenUploadProgess = false;
    }
  }

  validateFileSize(file: File): boolean {
    if (file.size > 6291456) {
      return false;
    } else {
      return true;
    }
  }

  validateFileType(name: string): boolean {
    const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
    if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' ||
      ext === 'tiff' || ext === 'tif' || ext === 'pdf' || ext === 'bmp') {
      return true;
    } else {
      return false;
    }
  }

  submitClaimData(claimData) {
    this.spinnerService.show();
    this.claimService.claimSubmit(claimData)
      .subscribe(res => {
        if (res.SuccessIndicator === 1) {
          this.claimDataManagementService.setClaimId(res.Message);
          this.claimDataManagementService.setClaimSuccessStattus(true);
        } else {
          this.claimDataManagementService.setClaimSuccessStattus(false);
        }
        this.spinnerService.hide();
        this.router.navigate(['/claim/confirmation']);
      });
  }

  closeClaimWiz() {
    this.cbfwCloseWarningModalPopupComponent.show();
  }

  clainWizardEnd() {
    const appDevice = this.localStorageService.getItem(Constants.appDeviceKey);
    if (this.appDeviceService.isAppDeviceAndroidOrIos(appDevice)) {
      this.router.navigate([Constants.policyLookupRoute]);
    } else {
      this.router.navigate([Constants.homeRoute]);
    }
  }

}
