import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClaimData } from '../models/claim-data.model';

@Injectable()
export class ClaimDataManagementService {
/* tslint:disable */ 
  private _claimData = new BehaviorSubject<ClaimData>(new ClaimData);
  ClaimData = this._claimData.asObservable();

  private _claimId = new BehaviorSubject<string>('');
  ClaimId = this._claimId.asObservable();

  private _claimSuccessStattus = new BehaviorSubject<boolean>(false);
  ClaimSuccessStattus = this._claimSuccessStattus.asObservable();

  constructor() { }

    setClaimData(claimData: ClaimData) {
      this._claimData.next(claimData);
    }

    setClaimId(claimId: string) {
      this._claimId.next(claimId);
    }

    setClaimSuccessStattus(status: boolean) {
      this._claimSuccessStattus.next(status);
    }
/* tslint:enable */
}
