export class ClaimData {
    PolicyLookUpData: any;
    PolicyData: any;
    IncidentData: any;
    IncidentDetails: any;
    ClaimType: number;
    CorporateEntityId: number;
    CheckDeliveryData: any;
    InvoiceData: any;
    CustomerData: any;
    CoveragePlan: any;
    AppId: any;
}
