import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AppValidationSettings } from '../models/app-validation-settings';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppValidationSettingsService {
  constructor(private http: HttpClient) {
  }
  getSettings(): Observable<any> {
    return this.http.get('./assets/app-validation.config.json')
      .pipe(
        map(this.extractData),
        catchError(this.handleErrors)
    );
  }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
  private handleErrors(error: any): Observable<any> {
    // console.error('An error occurred', error);
    return Observable.throw(error.message || error);
  }
}
