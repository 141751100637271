
<!--Middle section-->
<div class="clear"></div>

<!-- here banner component goes here -->
<lib-hero-banner></lib-hero-banner>

<div class="clear"></div>
<div><a href="#0" class="cd-top">Top</a></div>
<div class="clear"></div>
<!--Middle section End-->
