import { Component, OnInit } from '@angular/core';
import { CbfwEnrollmentMobileDeviceComponent } from 'cbfw-enrollment-mobile-device';
import { ViewChild } from '@angular/core';
import { Constants } from '../../../shared/utilities/constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-enrollment-mobile-device',
  templateUrl: './enrollment-mobile-device.component.html',
  styleUrls: ['./enrollment-mobile-device.component.css']
})
export class EnrollmentMobileDeviceComponent implements OnInit {

  /// CbfwEnrollmentMobileDeviceComponent view child
  @ViewChild(CbfwEnrollmentMobileDeviceComponent)
   cbfwEnrollmentMobileDeviceComponent: CbfwEnrollmentMobileDeviceComponent;

  constructor(
    private titleService: Title) {
    this.titleService.setTitle(Constants.siteName + ' | Device Enrollment');
  }

  ngOnInit() {
    /// set imagePlayStore LinkedUrl value
    this.cbfwEnrollmentMobileDeviceComponent.imagePlayStore.LinkedUrl = Constants.playStoreUrl;
    this.cbfwEnrollmentMobileDeviceComponent.imageAppStore.LinkedUrl = Constants.appStoreUrl;
    this.cbfwEnrollmentMobileDeviceComponent.labelMessage.DisplayValue = Constants.enrollMobileDeviceDisplayMessage;
  }

}
