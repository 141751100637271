import { Component, OnInit, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { CbfwModalPopupComponent, Controller, ModalType } from 'cbfw-modal-popup';
import { HtmlConstants } from '../../utilities/html-constants';
import { AppDeviceService } from '../../services/app-device.service';
import { Title } from '@angular/platform-browser';
import { Constants } from '../../../shared/utilities/constants';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faq = '';
  bannerUrl = '';

  public modalTermsAndConditionsType: string = ModalType.HtmlWithCloseButton;

  public modalTermsAndConditionsId = 'faq-terms-and-conditions-modal';

  public modalTermsAndConditionsButtonClose: Controller = {
    IsVisible: true,
    DisplayValue: 'Close'
  };
  public modalTermsAndConditionsTitle: Controller = {
    IsVisible: true,
    DisplayValue: 'Terms and Conditions'
  };
  public modalTermsAndConditionsMessage: Controller = {
    IsVisible: true,
    DisplayValue: HtmlConstants.modalTermsAndConditionsMessage
  };
  public appDevice: string;

  @ViewChild(CbfwModalPopupComponent) cbfwModalPopupComponent: CbfwModalPopupComponent;

  constructor(
    // private elRef: ElementRef,
    private appDeviceService: AppDeviceService,
    private titleService: Title
  ) {
    this.titleService.setTitle(Constants.siteName + ' | FAQs');
  }

  // ngAfterViewInit() {
  //   this.elRef.nativeElement.querySelector('a.terms-and-conditions-link').addEventListener('click',
  //     this.showTermsAndConditionsModal.bind(this));
  // }

  showTermsAndConditionsModal() {
    this.cbfwModalPopupComponent.modalType = this.modalTermsAndConditionsType;
    this.cbfwModalPopupComponent.modalId = this.modalTermsAndConditionsId;
    this.cbfwModalPopupComponent.buttonClose = this.modalTermsAndConditionsButtonClose;
    this.cbfwModalPopupComponent.title = this.modalTermsAndConditionsTitle;
    this.cbfwModalPopupComponent.htmlWithCloseButtonMessage = this.modalTermsAndConditionsMessage;
    this.cbfwModalPopupComponent.show(this.modalTermsAndConditionsId);
  }

  ngOnInit() {
    // this.appDeviceService.getAppDeviceValueAndHideFotterAndHeader();

    this.faq = HtmlConstants.faqContent;
    this.bannerUrl = HtmlConstants.faqImageUrl;
  }
}
