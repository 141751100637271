import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import 'rxjs/add/operator/toPromise';

import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { EnvConfig } from '../services/app-env-config';


const httpOptionst = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    })
};

@Injectable()
export class ContactUsService {

    private handleError: HandleError;
    private apiUrlEnrollment;

    constructor(
        private http: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        private envConfig: EnvConfig) {

        // pass this class name here;
        this.handleError = httpErrorHandler.createHandleError('StateService');

        this.apiUrlEnrollment = this.envConfig.get('apiUrlEnrollment');
    }


    postContactInformation(contactData: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };

        return this.http.post(this.apiUrlEnrollment + '/Enrollment/SendContactInformation', contactData, httpOptions)
            .pipe(
                catchError(this.handleError('postContactInformation', []))
            );
    }


}
