/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/cbfw-hero-banner/cbfw-hero-banner.ngfactory";
import * as i3 from "cbfw-hero-banner";
import * as i4 from "@angular/router";
import * as i5 from "./home.component";
import * as i6 from "../../services/app-settings-service";
import * as i7 from "@angular/platform-browser";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { heroBannerComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "clear"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "lib-hero-banner", [], null, null, null, i2.View_HeroBannerComponent_0, i2.RenderType_HeroBannerComponent)), i1.ɵdid(3, 49152, [[1, 4]], 0, i3.HeroBannerComponent, [i4.Router], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "clear"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "cd-top"], ["href", "#0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Top"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "clear"]], null, null, null, null, null))], null, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i5.HomeComponent, [i6.AppSettingsService, i7.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i5.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
