import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvConfig } from '../../shared/services/app-env-config';
import { HttpErrorHandler, HandleError } from '../../shared/services/http-error-handler.service';

@Injectable()
export class PolicyLookupService {

  private handleError: HandleError;
  private apiUrlClaim;


  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private envConfig: EnvConfig) {

    // pass this class name here;
    this.handleError = httpErrorHandler.createHandleError('PolicyLookupService');


    this.apiUrlClaim = this.envConfig.get('apiUrlClaim');
  }


  getPolicy(searchValue, email, searchtype, appId, maincategoryId, subcategoryId): Observable<any> {
    const httpOptions = {
        params: new HttpParams()
          .set('searchValue', searchValue)
          .set('email', email)
          .set('searchtype', searchtype)
          .set('appId', appId)
          .set('maincategoryId', maincategoryId)
          .set('subcategoryId', subcategoryId)
      };

    return this.http.get<any>(this.apiUrlClaim + '/Claim/SearchPolicyDetailsByAppId', httpOptions)
      .pipe(
        catchError(this.handleError('getRegistrationInfo', []))
      );
  }

}
