import { Injectable } from '@angular/core';
import { Constants } from '../utilities/constants';
import { LocalStorageService } from './local-storage.service';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppDeviceService {

  constructor(private localStorageService: LocalStorageService
  ) { }

  /// get app_device value and hide fotter and header
  getAppDeviceValueAndHideFotterAndHeader() {
    const appDevice = this.localStorageService.getItem(Constants.appDeviceKey);
    this.hideFotterAndHeader(appDevice);
  }

  /// hide fotter and header
  hideFotterAndHeader(appDevice) {
    if (this.isAppDeviceAndroidOrIos(appDevice)) {
      this.localStorageService.setItem(Constants.appDeviceKey, appDevice);
      $('footer').hide();
      $('header').hide();
     //// to hide footer and header nav padding
     $('body').attr('style', 'padding-top: 0px !important; margin-bottom: 10px !important;');
    }
  }

  /// is app_device android or ios
  isAppDeviceAndroidOrIos(appDevice) {
    return appDevice && (appDevice.toLocaleLowerCase() === 'ios' || appDevice.toLocaleLowerCase() === 'android');
  }

}
