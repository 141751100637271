import { Injectable, ElementRef, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { EnvConfig } from './app-env-config';
import { retry } from 'rxjs/internal/operators/retry';
import { Address } from 'cbfw-customerinfo-step';
// declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class AddressSuggestionService {

  private element: HTMLInputElement;

  private handleError: HandleError;
  private apiUrlEnrollment: string;

  constructor(
    private http: HttpClient,
    private envConfig: EnvConfig,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.apiUrlEnrollment = this.envConfig.get('apiUrlEnrollment');
    this.handleError = httpErrorHandler.createHandleError('StateService');
  }

  public loadAddressInformation(address: string): Observable<any> {
    return this.http.get(this.apiUrlEnrollment + '/v2/customer/GetAddressInformation?address=' +
      address)
      .pipe(
        catchError(this.handleError('getAddressSuggestion', []))
      );
  }

  public getAddressSuggestion(address: string): Observable<any> {
    return this.http.get(this.apiUrlEnrollment + '/v2/customer/GetAddressSuggestionsByInput?addressInfo=' +
      address)
      .pipe(
        catchError(this.handleError('getAddressSuggestion', []))
      );
  }
}
