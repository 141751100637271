import { Component, OnInit } from '@angular/core';
import { HtmlConstants } from '../../utilities/html-constants';
import { AppDeviceService } from '../../services/app-device.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicy = '';

  constructor(private appDeviceService: AppDeviceService) {
  }

  ngOnInit() {
    this.privacyPolicy = HtmlConstants.modalPrivacyPolicyMessage;
    // this.appDeviceService.getAppDeviceValueAndHideFotterAndHeader();
  }
}
