import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { EnvConfig } from './app-env-config';
import * as jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthenticateService {
    private apiUrlMaster: string;
    private apiUrlEnrollement: string;
    private apiKey: string;

    constructor(
        private http: HttpClient,
        private envConfig: EnvConfig
    ) {
        this.apiUrlMaster = this.envConfig.get('apiUrlMaster');
        this.apiUrlEnrollement = this.envConfig.get('apiUrlEnrollment');
        this.apiKey = this.envConfig.get('apiKey');
    }

    public userAuthenticationMaster(): Observable<any> {

        const date = new Date();
        const isoTime = date.toISOString();
        const keywithTime = this.apiKey + ':' + isoTime.split(':')[0];
        const encodedStr = btoa(keywithTime);
        const encryptedStr = this.encryptData(encodedStr);

        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'withCredentials': 'true',
            'Authorization': 'Basic ' + encryptedStr
        });
        return this.http.post(this.apiUrlMaster + '/Authenticate/RequestTokenV3', null, { headers: headers });
    }

    public isValidToken(encryptedToken: string) {

        if (encryptedToken !== null && encryptedToken !== undefined && encryptedToken !== '') {
            const tokenObject = jwt_decode(encryptedToken, null);
            const current_time = new Date().getTime() / 1000;
            if (current_time < tokenObject.exp) {
                return true;
            }
        }
        return false;
    }

    private encryptData(data) {
        const encKey = '7061737323313233';
        const encIV = '7061737323313233';
        const key = CryptoJS.enc.Utf8.parse(encKey);
        const iv = CryptoJS.enc.Utf8.parse(encIV);
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encrypted.toString();
    }
}
