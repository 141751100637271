/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./enroll-confirmation.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/cbfw-confirmationinfo-step/cbfw-confirmationinfo-step.ngfactory";
import * as i3 from "cbfw-confirmationinfo-step";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/router";
import * as i6 from "./enroll-confirmation.component";
var styles_EnrollConfirmationComponent = [i0.styles];
var RenderType_EnrollConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnrollConfirmationComponent, data: {} });
export { RenderType_EnrollConfirmationComponent as RenderType_EnrollConfirmationComponent };
export function View_EnrollConfirmationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { confirmationinfoStepComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "lib-confirmationinfo-step", [], null, [[null, "homeButtonClickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("homeButtonClickEvent" === en)) {
        var pd_0 = (_co.navigateToHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CbfwConfirmationinfoStepComponent_0, i2.RenderType_CbfwConfirmationinfoStepComponent)), i1.ɵdid(2, 114688, [[1, 4]], 0, i3.CbfwConfirmationinfoStepComponent, [i4.FormBuilder, i5.ActivatedRoute], null, { homeButtonClickEvent: "homeButtonClickEvent" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_EnrollConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-enroll-confirmation", [], null, null, null, View_EnrollConfirmationComponent_0, RenderType_EnrollConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i6.EnrollConfirmationComponent, [i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnrollConfirmationComponentNgFactory = i1.ɵccf("app-enroll-confirmation", i6.EnrollConfirmationComponent, View_EnrollConfirmationComponent_Host_0, {}, {}, []);
export { EnrollConfirmationComponentNgFactory as EnrollConfirmationComponentNgFactory };
