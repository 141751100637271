import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvConfig } from '../../shared/services/app-env-config';
import { HttpErrorHandler, HandleError } from '../../shared/services/http-error-handler.service';

@Injectable()
export class InvoiceService {

  private handleError: HandleError;
  private apiUrlClaim;
  private apiUrlEnrollment;


  constructor(private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private envConfig: EnvConfig) {

    // pass this class name here;
    this.handleError = httpErrorHandler.createHandleError('PolicyLookupService');

    this.apiUrlClaim = this.envConfig.get('apiUrlClaim');
    this.apiUrlEnrollment = this.envConfig.get('apiUrlEnrollment');
  }


  getReimbursementAmount(productId, corporateEntityId): Observable<any> {

    const httpOptions = {
        params: new HttpParams()
          .set('productId', productId)
          .set('corporateEntityId', corporateEntityId)
      };
    return this.http.get<any>(this.apiUrlClaim + '/Claim/GetCoveragePlanByProduct', httpOptions)
      .pipe(
        catchError(this.handleError('getReimbursementAmount', ))
      );
  }

  uploadRepairReceipt(esnFile: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', esnFile);

    return this.http.post(this.apiUrlEnrollment + '/Enrollment/UploadRepairInvoice', formData)
      .pipe(
        catchError(this.handleError('uploadRepairReceipt', []))
      );
  }

  uploadDmagedScreen(esnFile: File, policyId): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', esnFile);

    const httpOptions = {
        params: new HttpParams()
          .set('policyId', policyId)
      };

    return this.http.post(this.apiUrlClaim + '/Claim/UploadDamageScreenImage', formData, httpOptions)
      .pipe(
        catchError(this.handleError('uploadDmagedScreen', []))
      );
  }

}
