import { Component, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { AppSettings } from './shared/models/app-settings';
import { AppSettingsService } from './shared/services/app-settings-service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ContactUsService } from './shared/services/contact-us.service';
import { EnvConfig } from './shared/services/app-env-config';
import { CbfwContactUsComponent } from 'cbfw-contact-us';
import { CbfwFooterComponent } from 'cbfw-footer';
import { ConfigService } from './shared/services/config.service';
import { HtmlConstants } from './shared/utilities/html-constants';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LocalStorageService } from './shared/services/local-storage.service';
import { Constants } from './shared/utilities/constants';
import { AppDeviceService } from './shared/services/app-device.service';
import { Title } from '@angular/platform-browser';
import { AuthenticateService } from './shared/services/authenticate.service';
import { HeaderComponent } from 'cbfw-header';
import {Controller} from 'cbfw-menu';


declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'grip-insure-web';
  currentUrl: string;
  settings: AppSettings;
  headerConfig: any;
  footerConfig: any;
  contactUsSettings: any;
  corporateEntityId;
  appDevice: string;

  public notice: Controller = {
    IsVisible: Constants.showNotice,
    DisplayValue: HtmlConstants.noticeText
  };

  @ViewChild(CbfwFooterComponent) cbfwFooterComponent: CbfwFooterComponent;
  @ViewChild(HeaderComponent) headerComponent: HeaderComponent;

  constructor(
    private appSettingsService: AppSettingsService,
    private spinnerService: Ng4LoadingSpinnerService,
    private envConfig: EnvConfig,
    private contactUsService: ContactUsService,
    private configService: ConfigService,
    private cdref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private appDeviceService: AppDeviceService,
    private titleService: Title,
    private authService: AuthenticateService
  ) {

    this.corporateEntityId = this.envConfig.get('corporateEntityId');

    this.settings = this.configService.config;
    this.setCurrentYearInFooterConfig(this.settings.footer);
    this.headerConfig = this.settings.header;
    this.footerConfig = this.settings.footer;
    this.contactUsSettings = this.settings.contactUs;

    this.titleService.setTitle(Constants.siteName);
  }

  ngOnInit() {
    // get access token
    if (sessionStorage.getItem('requestToken') === null ||
      sessionStorage.getItem('requestToken') === undefined || sessionStorage.getItem('requestToken') === '') {
        this.authService.userAuthenticationMaster().subscribe((res: any) => {
          sessionStorage.setItem('requestToken', res.result);
        });
    }
  }


  ngAfterViewInit() {

    this.cbfwFooterComponent.modalTermsAndConditionsMessage.DisplayValue =
      HtmlConstants.modalTermsAndConditionsMessage;
    this.cbfwFooterComponent.modalContactUsMessage.DisplayValue = HtmlConstants.modalContactUsMessage;
    this.cbfwFooterComponent.modalPrivacyPolicyMessage.DisplayValue = HtmlConstants.modalPrivacyPolicyMessage;
    this.headerComponent.cbfwMenuComponent.menuItems = Constants.menuItems;
    this.cbfwFooterComponent.quickLinksTitle.IsVisible = Constants.quicklinksTitleIsVisible ;
    this.cbfwFooterComponent.quickLinksPrivacyPolicy.IsVisible = Constants.quickLinksPrivacyPolicyIsVisible;
    this.cbfwFooterComponent.quickLinksTermsConditions.IsVisible = Constants.quickLinksTermsConditionsIsVisible;
    this.cbfwFooterComponent.quickLinksContactUs.IsVisible = Constants.quickLinksContactUsIsVisible;
    this.cdref.detectChanges();

    this.activatedRoute.queryParams.subscribe(params => {
      const appDevice = params[Constants.appDeviceKey];
      this.appDeviceService.hideFotterAndHeader(appDevice);
    });
  }

  contactUsSubmit(formGroup) {
    const postData: any = {};
    const contacData: any = {};

    contacData.Name = formGroup.nameCtrl;
    contacData.EMail = formGroup.emailCtrl;
    contacData.PhoneNumber = formGroup.phoneNumberCtrl;
    contacData.Comments = formGroup.commentCtrl;
    contacData.ToAddress = this.contactUsSettings.toAddress;
    contacData.FromAddress = this.contactUsSettings.fromAddress;
    contacData.Subject = this.contactUsSettings.emailSubject;
    contacData.CorporateEntityId = this.corporateEntityId;

    postData.ContactUs = contacData;

    this.spinnerService.show();
    this.contactUsService.postContactInformation(postData)
      .subscribe(res => {
        if (res === 1) {
          this.cbfwFooterComponent.showContactUsSuccessMsg();
          this.cbfwFooterComponent.resetContactUsForm();
        } else {
          this.cbfwFooterComponent.showContactUsErrorMsg();
        }

        this.spinnerService.hide();
      });
  }

  private setCurrentYearInFooterConfig(footer: any) {
    footer.copyrightStatement = footer.copyrightStatement.replace(
      '%currentYear%',
      new Date().getFullYear()
    );
  }


}
