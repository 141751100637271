/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./policy-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/cbfw-policy-details/cbfw-policy-details.ngfactory";
import * as i3 from "cbfw-policy-details";
import * as i4 from "./policy-details.component";
import * as i5 from "../../services/claim-data-management.service";
import * as i6 from "@angular/router";
import * as i7 from "../../services/claim.service";
import * as i8 from "ng4-loading-spinner";
import * as i9 from "../../../shared/services/app-device.service";
import * as i10 from "../../../shared/services/local-storage.service";
var styles_PolicyDetailsComponent = [i0.styles];
var RenderType_PolicyDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PolicyDetailsComponent, data: {} });
export { RenderType_PolicyDetailsComponent as RenderType_PolicyDetailsComponent };
export function View_PolicyDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cbfwPolicyDetailsComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "lib-policy-details", [], null, [[null, "cancelClick"], [null, "policyDetailsData"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelClick" === en)) {
        var pd_0 = (_co.cancelClick() !== false);
        ad = (pd_0 && ad);
    } if (("policyDetailsData" === en)) {
        var pd_1 = (_co.CheckValidityForFileClaim($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CbfwPolicyDetailsComponent_0, i2.RenderType_CbfwPolicyDetailsComponent)), i1.ɵdid(2, 49152, [[1, 4]], 0, i3.CbfwPolicyDetailsComponent, [], { policyData: [0, "policyData"] }, { policyDetailsData: "policyDetailsData", cancelClick: "cancelClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.policyData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PolicyDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-policy-details", [], null, null, null, View_PolicyDetailsComponent_0, RenderType_PolicyDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i4.PolicyDetailsComponent, [i5.ClaimDataManagementService, i6.Router, i7.ClaimService, i8.Ng4LoadingSpinnerService, i9.AppDeviceService, i10.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PolicyDetailsComponentNgFactory = i1.ɵccf("app-policy-details", i4.PolicyDetailsComponent, View_PolicyDetailsComponent_Host_0, {}, {}, []);
export { PolicyDetailsComponentNgFactory as PolicyDetailsComponentNgFactory };
