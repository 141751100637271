/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./enrollment-device-type.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/cbfw-enrollment-device-type/cbfw-enrollment-device-type.ngfactory";
import * as i3 from "cbfw-enrollment-device-type";
import * as i4 from "@angular/forms";
import * as i5 from "./enrollment-device-type.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../shared/services/device-info.service";
var styles_EnrollmentDeviceTypeComponent = [i0.styles];
var RenderType_EnrollmentDeviceTypeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnrollmentDeviceTypeComponent, data: {} });
export { RenderType_EnrollmentDeviceTypeComponent as RenderType_EnrollmentDeviceTypeComponent };
export function View_EnrollmentDeviceTypeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cbfwEnrollmentDeviceTypeComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "lib-enrollment-device-type", [], null, [[null, "cancelEvent"], [null, "nextEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelEvent" === en)) {
        var pd_0 = (_co.cancelClick() !== false);
        ad = (pd_0 && ad);
    } if (("nextEvent" === en)) {
        var pd_1 = (_co.nextClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CbfwEnrollmentDeviceTypeComponent_0, i2.RenderType_CbfwEnrollmentDeviceTypeComponent)), i1.ɵdid(2, 49152, [[1, 4]], 0, i3.CbfwEnrollmentDeviceTypeComponent, [i4.FormBuilder], { categoryList: [0, "categoryList"], dropDownPlaceholder: [1, "dropDownPlaceholder"] }, { cancelEvent: "cancelEvent", nextEvent: "nextEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productCategoryList; var currVal_1 = "Select Category"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_EnrollmentDeviceTypeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-enrollment-device-type", [], null, null, null, View_EnrollmentDeviceTypeComponent_0, RenderType_EnrollmentDeviceTypeComponent)), i1.ɵdid(1, 4308992, null, 0, i5.EnrollmentDeviceTypeComponent, [i6.Router, i7.Title, i8.DeviceInfoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnrollmentDeviceTypeComponentNgFactory = i1.ɵccf("app-enrollment-device-type", i5.EnrollmentDeviceTypeComponent, View_EnrollmentDeviceTypeComponent_Host_0, {}, {}, []);
export { EnrollmentDeviceTypeComponentNgFactory as EnrollmentDeviceTypeComponentNgFactory };
